import React from "react";
import AshokImg from "../components/images/Ashok Leyland.png";
import MotorImg from "../components/images/Tata Motor.png";
import HindustanImg from "../components/images/hindustan-zinc7644.png";
import BajajImg from "../components/images/bajaj Auto.png";
import PerfettiImg from "../components/images/Perfetti.png";
import CenturyImg from "../components/images/Century-Pulp.png";
import Delta from "../components/images/delta.png"
import Neel from "../components/images/Neel.png"
import Dukes from "../components/images/Dukes.png"
import Manindra from "../components/images/Mahindra.png"
import Parle from "../components/images/Parle.png"
import LSC from "../components/images/LSC.png"
import Nestle from "../components/images/Nestle.png"
import Belrise from "../components/images/Belrise.png"
import Steelbird from "../components/images/Steelbird.png"
import Archidply from "../components/images/Archidply.png"
import Voltas from "../components/images/Voltas.png"
import abc from "../components/images/abc.png"
import Marquee from "react-fast-marquee";


interface Company {
  name: string;
  image: string;
}


const companyData: Company[] = [
  { name: "Ashok Leyland", image: AshokImg },
  { name: "Tata Motor", image: MotorImg },
  { name: "Hindustan Zinc", image: HindustanImg },
  { name: "Bajaj Auto", image: BajajImg },
  { name: "Perfetti", image: PerfettiImg },
  { name: "Century Pulp", image: CenturyImg },
  { name: "Delta", image: Delta },
  { name: "Neel", image: Neel },
  { name: "Dukes", image: Dukes },
  { name: "Mahindra", image: Manindra },
  { name: "Parle", image: Parle },
  { name: "LSC", image: LSC },
  { name: "Nestle", image: Nestle },
  { name: "Belrise", image: Belrise },
  { name: "Steelbird", image: Steelbird },
  { name: "Archidply", image: Archidply },
  { name: "Voltas", image: Voltas },
  { name: "ABC", image: abc },
];

const CompanySlider: React.FC = () => {
  const rtl = false;
  return (
    <div className="flex justify-center items-center h-auto w-full pt-3 pb-5 bg-white">
      <Marquee direction={rtl ? "right" : "left"} speed={58} className="w-full">
        {companyData.map((company, index) => (
          <div
            key={index}
            className="h-[45px] text-center mx-4"
          >
            <img
              src={company.image}
              alt={company.name}
              className="company-image w-full h-full object-contain"
            />
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default CompanySlider;
