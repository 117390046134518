import { toPng } from "html-to-image";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import QRCode from "qrcode.react";
import { IoShareSocialOutline } from "react-icons/io5";
import { useChangeEmailAndNameMutation } from "../../../Redux/Api/Contractor";
const MobileProfileInfo = () => {
    const qrRef = useRef<HTMLDivElement>(null);
    const userDetails = useSelector((state: RootState) => state.Login.ContractorData);
    const referralCode = userDetails?.referralCode || "ihuiu";

    const handleShareToWhatsApp = async () => {
        if (qrRef.current) {
            const dataUrl = await toPng(qrRef.current);
            const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
                `Use my referral code: ${referralCode}`
            )}&url=${encodeURIComponent(dataUrl)}`;
            window.open(whatsappUrl, "_blank");
        }
    };

    


    return (
        <div className="sm:hidden  h-auto bg-white   px-6   mx-auto  mb-6   ">
            <ul className="px-8 py-3 text-[16px] font-medium space-y-4">
                <li className="flex flex-col gap-1 border-b pb-2">
                    <p className="text-gray-700">Name</p>
                    <p className="text-[#B8B6B6]">{userDetails?.name}</p>
                </li>
                <li className="flex flex-col gap-1 border-b pb-2">
                    <p className="text-gray-700">Phone Number</p>
                    <p className="text-[#B8B6B6]">{userDetails?.phoneNumber}</p>
                </li>
                <li className="flex flex-col gap-1">
                    <p className="text-gray-700">Email</p>
                    <p className="text-[#B8B6B6]">{userDetails?.email}</p>
                </li>
            </ul>

            <div className="w-full text-center flex flex-col gap-1 mx-auto items-center mt-10">
                <div className="w-[115px] h-[115px] bg-white rounded-lg shadow-md border-4 ring-[3px] ring-[#5097A4] flex items-center justify-center border-[#2D99A573]">
                    <QRCode
                        value={referralCode}
                        size={100}
                        className="transition-transform transform "
                    />
                </div>
                <p className="text-[13px] font-medium mt-1 text-[#888888]">
                    Referral Code
                </p>
                <div className="text-[16px] font-semibold text-gray-800">
                    {referralCode}
                </div>
                <button
                    onClick={handleShareToWhatsApp}
                    className="text-green-600 flex items-center gap-2 text-[16px] font-medium ">
                    <IoShareSocialOutline fontSize={"1.60rem"} /> Share QR Code
                </button>
            </div>
        </div>
    );
};

export default MobileProfileInfo;

// import { toPng } from 'html-to-image';
// import { useRef } from 'react';
// import { FaUser, FaPhone, FaEnvelope } from 'react-icons/fa';
// import { useSelector } from 'react-redux';
// import { RootState } from '../../../Redux/store';
// import QRCode from 'qrcode.react';
// import { IoMdShare } from 'react-icons/io';

// const MobileProfileInfo = () => {
// const qrRef = useRef<HTMLDivElement>(null);
// const userDetails = useSelector((state: RootState) => state.Login.ContractorData);
// const referralCode = userDetails?.referralCode || "ihuiu";

// const handleShareToWhatsApp = async () => {
//     if (qrRef.current) {
//         const dataUrl = await toPng(qrRef.current);
//         const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
//             `Use my referral code: ${referralCode}`
//         )}&url=${encodeURIComponent(dataUrl)}`;
//         window.open(whatsappUrl, "_blank");
//     }
// };

//     return (
//         <div className="sm:hidden h-auto bg-gradient-to-r from-[#CEE4E8] to-[#A0DDE6] shadow-lg rounded-lg p-6 w-[90%] mt-6 mx-auto border border-gray-300">
//             <ul className="space-y-6">
//                 <li className="flex items-center gap-3 bg-white p-4 rounded-lg shadow-md transition-transform transform hover:scale-105">
//                     <FaUser className="text-blue-500 text-xl" />
//                     <div className="flex flex-col">
//                         <p className="text-gray-800 font-semibold">Name</p>
//                         <p className="text-gray-700">Pawan Kumar</p>
//                     </div>
//                 </li>
//                 <li className="flex items-center gap-3 bg-white p-4 rounded-lg shadow-md transition-transform transform hover:scale-105">
//                     <FaPhone className="text-green-500 text-xl" />
//                     <div className="flex flex-col">
//                         <p className="text-gray-800 font-semibold">Phone Number</p>
//                         <p className="text-gray-700">86352 45690</p>
//                     </div>
//                 </li>
//                 <li className="flex items-center gap-3 bg-white p-4 rounded-lg shadow-md transition-transform transform hover:scale-105">
//                     <FaEnvelope className="text-red-500 text-xl" />
//                     <div className="flex flex-col">
//                         <p className="text-gray-800 font-semibold">Email</p>
//                         <p className="text-gray-700">yash@gmial.com</p>
//                     </div>
//                 </li>
//             </ul>

//             <div className="w-full h-auto flex justify-center mt-6">
//                 <div className="w-full flex flex-col items-center p-4 rounded-xl shadow-md bg-white border-4 border-[#5097A4]">
//                     <div className="mb-2 text-gray-500 text-center dark:text-gray-400">
//                         Referral Code
//                     </div>
//                     <div className="flex flex-col items-center w-full gap-2" ref={qrRef}>
//                         <QRCode value={referralCode} size={128} className="transition-transform transform hover:scale-110" />
//                         <div className="text-lg font-semibold text-gray-800">
//                             {referralCode}
//                         </div>
//                     </div>
//                     <div className="flex w-full gap-2 mt-4 items-center justify-center">
//                         <span className="py-2 text-4xl cursor-pointer" onClick={handleShareToWhatsApp}>
//                             <IoMdShare className="h-6 w-6 text-green-600" />
//                         </span>
//                         <button onClick={handleShareToWhatsApp} className="text-green-600 font-semibold">
//                             Share QR Code
//                         </button>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default MobileProfileInfo;
