import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { BiSolidCoinStack } from "react-icons/bi";
import { ConfirmModal, ZoomInImage } from "../../../utils/CustomModal";
import coverImage from "../../../assets/profile/coverImage.png";
import smCoverImage from "../../../assets/profile/smCoverImage.jpg";
import { toast } from "react-toastify";
import { useChangeBagroundImageMutation } from "../../../Redux/Api/Contractor";
import { useCreateImageURLMutation } from "../../../Redux/Api/Admin/Image";
import MyProfileInfo from "./MyProfileInfo";
import { TbPhotoEdit } from "react-icons/tb";
import ProfileImage from "./ProfileImage";
import { BsThreeDotsVertical } from "react-icons/bs";
import BottomProfileMenu from "./BottomProfileMenu";

export const MyProfile = () => {

  const userDetails = useSelector((state: RootState) => state.Login.ContractorData);
  const [isZoomInProfile, setIsZoomInProfile] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const [updateBackgroundImage, { isLoading, isSuccess, isError }] = useChangeBagroundImageMutation();
  const [getImageURL] = useCreateImageURLMutation();



  const styles = {
    boxShadowStyle: {
      boxShadow: "0px 0.5px 7px 0px #00000040",
    },
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Background image updated successfully!", {
        position: "top-center",
      });
      setIsConfirmModalOpen(false);
      setImagePreview(null);
      setUploadedFile(null);
    } else if (isError) {
      toast.error(
        "Failed to update background image. Please try again later."
      );
      setIsConfirmModalOpen(false);
    }
  }, [isSuccess, isError]);

  const handleZoomProfile = () => {
    if (userDetails?.avatar) {
      setIsZoomInProfile(true);
    } else {
      toast.error("Please upload your profile picture to zoom in.");
    }
  };

  const handleBackgroundChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setUploadedFile(file);
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64Image = reader.result as string;
        setImagePreview(base64Image);
        setIsConfirmModalOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    if (uploadedFile) {
      const body = new FormData();
      body.append("image", uploadedFile);
      try {
        const response = await getImageURL(body).unwrap();
        updateBackgroundImage({ image: response[0] });
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const onClose = () => {
    setIsConfirmModalOpen(false);
    setUploadedFile(null);
    setImagePreview(null);
  };



  return (
    <>

      <div className="w-full min-h-screen flex flex-col relative font-poppins sm:font-inter">
        
        <div className="flex-grow overflow-hidden w-full mx-auto h-auto relative">
          {/* <button onClick={handleOpenProfileMenu} className="absolute right-4 top-4 sm:hidden z-30 bg-black text-white h-10 w-10 flex justify-center items-center rounded-full">
            <BsThreeDotsVertical fontSize={"1.40rem"} />
          </button> */}
          <div
            className="w-full mx-auto border-white border-b-[4px] h-auto relative sm:shadow-[0px_0.5px_7px_0px_#00000040]"
          >
            <img
              src={
                imagePreview
                  ? imagePreview
                  : userDetails?.backgroundImage || coverImage
              }
              alt="User Cover"
              className="w-full h-[156px] 2xl:h-[170px] object-cover object-center hidden sm:block"
            />
            <img
              src={ userDetails?.backgroundImage || smCoverImage
              }
              alt="User Cover"
              className="w-full h-[176px] object-cover object-center block sm:hidden"
            />
            <div className="absolute right-4 bottom-4  hidden sm:block">
              <button
                onClick={() =>
                  document
                    .getElementById("backgroundInput")
                    ?.click()
                }
                className="w-11 h-11 hidden sm:flex justify-center items-center bg-white rounded-full ">
                <TbPhotoEdit fontSize={"1.60rem"} />
              </button>

              <input
                type="file"
                id="backgroundInput"
                className="hidden"
                onChange={handleBackgroundChange}
                accept="image/*"
              />
            </div>
          </div>
          <div className="flex flex-col  justify-center sm:flex-row w-full max-w-[1536px] items-start sm:justify-start font-inter relative ">
            <ProfileImage handleZoomProfile={handleZoomProfile} />

            <div className="flex flex-col items-center  sm:flex-row sm:justify-around md:justify-between w-full sm:w-[calc(100%-176px)]  sm:items-start gap-2 mt-4 ">
              <div className=" hidden sm:block text-xl md:text-[25px] ml-1 font-bold">
                {userDetails?.name}
              </div>
              <div
                className=" absolute sm:static right-5 top-2 flex items-center sm:mr-10 py-1 px-3 text-[14px] md:text-[20px] font-bold rounded-2xl tracking-wider"
                style={styles.boxShadowStyle}>
                <BiSolidCoinStack className="text-[#FF9900] mr-[1px] " />
                {userDetails?.totalReward || 0}
              </div>
            </div>
          </div>

          <MyProfileInfo />


          <ZoomInImage
            isOpen={isZoomInProfile}
            setIsOpen={setIsZoomInProfile}
            imageUrl={userDetails?.avatar}
            altText="abc"
          />
          <ConfirmModal
            isOpen={isConfirmModalOpen}
            handleConfirm={handleSubmit}
            handleClose={onClose}
            text="Are you sure you want to change your background image?"
            heading="Confirm Background Change"
            isLoading={isLoading}
          />
        </div>
      </div>

    </>
  );
};
