import shape from "../../../assets/assets/profile_heading_shape.png";

type Props = {
    heading: string;
}
const ProfileHeading = ({heading}:Props) => {
    return (
        <div
            className="w-full h-[119px] relative flex sm:hidden z-10 items-center justify-center bg-cover bg-center font-poppins"
            style={{ backgroundImage: `url(${shape})` }}
        >
            {/* Add a heading */}
            <h1 className="text-xl font-medium pb-7 text-center text-white ">
                {heading}
            </h1>
        </div>
    );
};

export default ProfileHeading;
