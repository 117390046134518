import CategoryCard from "./categorycard";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Category } from "../Components/types";
import { chawlaispat } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";
import { IoIosSearch } from "react-icons/io";
import { HashLoader, RingLoader } from "react-spinners";
import NoData from "../../common/NoData";
import { useAllCategoriesQuery } from "../../Redux/Api/Category";
import useDebounce from "../../common/hooks/useDebounce";
import { RxDashboard } from "react-icons/rx";
import MobileCategoryCard from "./mobileCategoryCard";
import { TfiViewList } from "react-icons/tfi";
const CategoryPage = () => {
	const [search, setSearch] = useState<string>("");
	const [categoriesLoading, setCategoriesLoading] = useState<boolean>(false);
	const [isGrid, setIsGrid] = useState<boolean>(true);
	const [isFocused, setIsFocused] = useState<boolean>(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const handleSearchClick = () => {
		if (inputRef.current) {
			inputRef.current.blur();
		}
	};

	const debounceCategoriesSearch = useDebounce(search, 400);
	const { data: categoriesData, isFetching: categoriesFetching } =
		useAllCategoriesQuery(
			debounceCategoriesSearch
				? { search: debounceCategoriesSearch.trim() }
				: undefined,
			{
				refetchOnMountOrArgChange: true,
			}
		);

	const onChnageHandler = (e: ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value);
	};

	useEffect(() => {
		if (categoriesFetching) {
			setCategoriesLoading(true);
		} else {
			setCategoriesLoading(false);
		}
	}, [categoriesFetching]);

	const title = "Categories | Building Material Supplier | Chawla Ispat";
	const description =
		"Browse various categories on Chawla Ispat to find what you need.";
	const canonicalUrl = chawlaispat;

	usePageMeta(title, description, canonicalUrl);

	return (
		<div className="font-inter bg-white">
			<div className="py-3 sm:py-12 w-11/12 m-auto ">
				<div className="flex justify-between flex-col sm:flex-row">
					<div className=" flex justify-between items-center">
						<div className="font-[700] font-poppins sm:font-inter sm:text-[32px] leading-[38px]">
							{" "}
							All Category
						</div>
						<div className=" sm:hidden flex gap-2 text-[20px]">
							<div className="w-full">
								<div className="relative right-0">
									<ul
										className="flex flex-wrap gap-1 p-1 list-none rounded-md relative overflow-hidden"
										role="list">
										{/* Animated Background Color Change */}
										<div className="absolute top-0 bottom-0 left-0 w-1/2 rounded-md" />
										{/* Grid View Button */}
										<li className="flex-auto text-center relative">
											<button
												className={`flex items-center justify-center w-full p-1.5  text-lg transition-all ease-in-out rounded-md cursor-pointer ${isGrid
														? "bg-[#5097A4] text-white shadow-lg"
														: "bg-gray-100 text-gray-600 hover:bg-gray-50"
													}`}
												onClick={() => setIsGrid(true)}
												aria-pressed={isGrid}>
												<RxDashboard className="w-4 h-4" />
											</button>
										</li>

										{/* List View Button */}
										<li className="flex-auto text-center relative">
											<button
												className={`flex items-center justify-center w-full p-1.5 text-lg transition-all ease-in-out rounded-md cursor-pointer ${!isGrid
														? "bg-[#5097A4] text-white shadow-lg"
														: "bg-gray-100 text-gray-600 hover:bg-gray-50"
													}`}
												onClick={() => setIsGrid(false)}
												aria-pressed={!isGrid}>
												<TfiViewList className="w-4 h-4" />
											</button>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="relative  my-2 border rounded-md ">
						<input
							type="text"
							ref={inputRef}
							className="input-style"
							placeholder="Search"
							onFocus={() => setIsFocused(true)}
							onBlur={() => setIsFocused(false)}
							onChange={onChnageHandler}
							value={search}
						/>
						{!isFocused && (
							<span
								onClick={handleSearchClick}
								className={`absolute right-3 top-1/2 transform -translate-y-1/2 text-2xl text-gray-600 cursor-pointer transition-all duration-300 ${isFocused
										? "translate-x-5 opacity-0 pointer-events-none"
										: "translate-x-0 opacity-100"
									}`}>
								<IoIosSearch className="h-4 w-4" />
							</span>
						)}
					</div>
				</div>

				{categoriesLoading ? (
						<div className="flex justify-center items-center h-[50vh]">
							<div className=" hidden sm:block">
								<RingLoader color="#5097A4" size={150} />
							</div>
							<div className="sm:hidden">
								<HashLoader color="#5097A4" size={60} />
							</div>
						</div>
					
				) : categoriesData?.data?.length > 0 ? (
					<div className="sm:px-1 pt-2 pb-4 sm:py-4 flex flex-wrap items-center sm:justify-evenly gap-4 ">
						<div className="  hidden sm:grid w-full gap-3 sm:gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 sm:p-4 ">
							{categoriesData?.data?.map(
								(category: Category, i: number) => (
									<CategoryCard
										key={category?.id}
										category={category}
									/>
								)
							)}
						</div>
						<div
							className={`grid sm:hidden w-full gap-3 transition-opacity duration-500 ease-in-out ${isGrid ? "grid-cols-2" : "grid-cols-1 "
								}`}>
							{categoriesData?.data?.map(
								(category: Category, i: number) => (
									<MobileCategoryCard
										key={category?.id}
										category={category}
										isGrid={isGrid}
									/>
								)
							)}
						</div>

						{/* <div className="flex flex-wrap justify-center gap-4 sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-6 py-8">
          {categoriesData?.data?.map((category: Category,i:number) => (
            <div className="flex justify-center w-full" key={i}>
              <CategoryCard key={category?.id} category={category} />
            </div>
          ))} */}
					</div>
				) : (
					<NoData messageText="No categories found. Please check your filters." />
				)}
			</div>
		</div>
	);
};

export default CategoryPage;
