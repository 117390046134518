
import MobileCarousel from "./components/MobileCarousel";
import TopCompanyScroll from "./components/TopCompaniesScroll";
import { ICategoryData } from "./components/types";
import ServicesSection from "./components/ServicesSection";
import companyImage from "../../assets/assets/companyImage.png";
import categoryImage from "../../assets/assets/categoryImage.png";
import ProductsSection from "./components/ProductsSection";
import { useGetCompanyProductMutation } from "../../Redux/Api/Admin/Company";
import { useCallback, useEffect } from "react";
import DemandProductSection from "./components/DemandProductSection";
import TopCategoriesScroll from "./components/TopCategoriesScroll ";
import { motion } from "framer-motion";
import ButtonWithAnimation from "./components/ButtonWithAnimation";
import { gradientStyle, slideIn, springIn, staggerContainer } from "./components/StyleComponent";
import DifferentBranchesSection from "./components/DifferentBranchesSection";
interface MobileHomeProps {
  carouselData: ICategoryData[];
  carouselLoading: boolean;
}


const MobileHome = ({ carouselData, carouselLoading }: MobileHomeProps) => {
  const [mutation, { data, isLoading }] = useGetCompanyProductMutation();

  const handleFetchData = useCallback(() => {
    mutation({
      category: [],
      search: "",
    });
  }, [mutation])

  useEffect(() => {
    handleFetchData()
  }, []);

  return (
    <motion.div
      className="w-full h-auto sm:hidden mb-6"
      variants={staggerContainer}
      initial="hidden"
      animate="visible">
      <motion.div variants={springIn}>
        <TopCategoriesScroll />
      </motion.div>

      <motion.div
        className="w-full"
        style={gradientStyle}
        variants={staggerContainer}>
        <motion.div variants={slideIn}>
          <MobileCarousel
            data={carouselData}
            isLoading={carouselLoading}
          />
        </motion.div>

        <motion.div variants={springIn}>
          <TopCompanyScroll data={data} isLoading={isLoading} />
        </motion.div>

        <motion.div
          className="w-full my-5 flex justify-center gap-10"
          variants={springIn}>
          <ButtonWithAnimation
            id="companies"
            to="/company"
            imgSrc={companyImage}
            imgAlt="company">
            View All Company
          </ButtonWithAnimation>
          <ButtonWithAnimation
            id="categories"
            to="/categories"
            imgSrc={categoryImage}
            imgAlt="category">
            View All Category
          </ButtonWithAnimation>
        </motion.div>

        <motion.div variants={springIn}>
          <ServicesSection />
        </motion.div>

        <motion.div variants={slideIn}>
          <ProductsSection />
        </motion.div>

        <motion.div variants={springIn}>
          <DifferentBranchesSection />
        </motion.div>
        <motion.div variants={springIn}>
          <DemandProductSection />
        </motion.div>
      </motion.div>


    </motion.div>
  );
};

export default MobileHome;

// import MobileCarousel from "./components/MobileCarousel"
// import TopCategoriesScroll from "./components/TopCategoriesScroll "
// import TopCompanyScroll from "./components/TopCompaniesScroll"
// import { ICategoryData } from "./components/types"
// import ServicesSection from "./components/ServicesSection"
// import companyImage from "../../assets/assets/companyImage.png"
// import categoryImage from "../../assets/assets/categoryImage.png"
// import { Link } from "react-router-dom"
// import ProductsSection from "./components/ProductsSection"
// import { useGetCompanyProductMutation } from "../../Redux/Api/Admin/Company"
// import { useEffect } from "react"
// import DemandProductSection from "./components/DemandProductSection"

// interface MobileHomeProps {
//     carouselData: ICategoryData[]
//     carouselLoading: boolean
// }
// const gradientStyle = {
//     background: "linear-gradient(180.46deg, #E1E7EF 0.4%, rgba(225, 231, 239, 0.75) 88.58%, rgba(225, 231, 239, 0) 99.6%)"
// };

// const MobileHome = ({ carouselData, carouselLoading }: MobileHomeProps) => {
//     const [mutation, { data, isLoading }] = useGetCompanyProductMutation();
//     useEffect(() => {
//         mutation({
//             category: [],
//             search: "",
//         });
//     }, [mutation]);
//     const buttonStyle = "w-[128px] h-[25px] bg-[#FF9300] text-[10px]  font-medium rounded-[4px] leading-[12.1px] text-[#2F2C2D] flex justify-center items-center gap-2 shadow-md"
//     return (
//         <div className="w-full h-auto sm:hidden mb-6">
//             <TopCategoriesScroll />
//             <div className="w-full" style={gradientStyle}>
//                 <MobileCarousel data={carouselData} isLoading={carouselLoading} />
//                 <TopCompanyScroll data={data} isLoading={isLoading} />
//                 <div className="w-full my-5 flex justify-center gap-10">
//                     <Link to="/company" className={buttonStyle} >
//                         View All Company  <img src={companyImage} alt="company" className="h-[17px] w-[17px]" />
//                     </Link>
//                     <Link to="/categories" className={buttonStyle}>
//                         View All Category <img src={categoryImage} alt="category" className="h-[17px] w-[17px]" />
//                     </Link>
//                 </div>
//                 <ServicesSection />
//                 <ProductsSection  data={data} />
//                 <DemandProductSection/>
//             </div>
//         </div>
//     )
// }

// export default MobileHome
