import { IoArrowForwardSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { ICategoryData } from "./types";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const DemandProductSection = () => {
    const navigate = useNavigate()
    const allCategories = useSelector(
        (state: RootState) => state.values.allCategories
    );

    const handleItemClick = (_id: string | null, name: string) => {
        const type = "category"
        if (_id) navigate(`/items/${_id}/${type}/${encodeURIComponent(name)}`, { state: { id: _id } });
        else toast.warning("Something went wrong! Please try again.");
    };

    return (
        <div className="w-full bg-white py-3 px-4">
            <div className=" flex justify-between items-center">
                <span className="font-poppins font-medium text-[17px] leading-[20.57px] ">
                    {" "}
                    Most Demand Product |{" "}
                    <span className="font-poppins font-medium text-[12px] leading-[14.52px]">
                        For more...
                    </span>
                </span>
                <Link to={"/categories"} className="w-[25px] h-[25px] text-[12px] flex flex-col items-center justify-center rounded-full bg-[#FF9300]">
                    <IoArrowForwardSharp />
                </Link>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-3 ">
                {
                    allCategories?.slice(6, 12)?.map((category: ICategoryData, index: number) => (
                        <div 
                        key={index}
                        onClick={() => handleItemClick(category?.id, category?.name)}
                        className="col-span-1 shrink-0 bg-white shadow-md rounded-lg overflow-hidden transform transition-transform hover:scale-105">
                            <div className="w-full bg-gray-200 h-36 p-2">
                                <img src={
                                    category.imageCarouselDetails?.length > 0 &&
                                        category.imageCarouselDetails[0]?.image
                                        ? category.imageCarouselDetails[0].image
                                        : ""
                                }
                                    className="w-full h-full object-contain rounded-t-lg"
                                    alt={category.name} />
                            </div>
                            <div className="p-2">
                                <span className="font-medium text-xs tracking-[0.1px] text-slate-800 truncate block">
                                    {category.name.length > 30 ? `${category.name.slice(0, 30)}...` : category.name}
                                </span>
                                <span className="text-[10px] text-gray-500">Count: {category.companyCount}</span>
                                <button className="mt-2 w-full text-white text-[10px] font-semibold bg-[#0F172B] rounded-md py-1 transition-all duration-300"
                                    onClick={() => handleItemClick(category?.id, category?.name)}
                                >
                                    Purchase Now
                                </button>
                            </div>
                        </div>
                    ))

                }
            </div>
        </div>
    );
};

export default DemandProductSection;
// import { IoArrowForwardSharp } from "react-icons/io5";
// import { useSelector } from "react-redux";
// import { RootState } from "../../../Redux/store";
// import { ICategoryData } from "./types";
// import { Link, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// const DemandProductSection = () => {
//     const navigate = useNavigate()
//     const allCategories = useSelector(
//         (state: RootState) => state.values.allCategories
//     );

//     const handleItemClick = (_id: string | null, name: string) => {
//         const type = "category"
//         if (_id) navigate(`/items/${_id}/${type}/${encodeURIComponent(name)}`, { state: { id: _id } });
//         else toast.warning("Something went wrong! Please try again.");
//     };

//     return (
//         <div className="w-full bg-white py-3 px-6">
//             <div className=" flex justify-between items-center">
//                 <span className="font-poppins font-medium text-[17px] leading-[20.57px] ">
//                     {" "}
//                     Most Demand Product |{" "}
//                     <span className="font-poppins font-medium text-[12px] leading-[14.52px]">
//                         For more...
//                     </span>
//                 </span>
//                 <Link to={"/categories"} className="w-[25px] h-[25px] text-[12px] flex flex-col items-center justify-center rounded-full bg-[#FF9300]">
//                     <IoArrowForwardSharp />
//                 </Link>
//             </div>
//             <div className=" flex flex-wrap  justify-between mt-3 gap-y-4">
//                 {
//                     allCategories?.slice(6, 12)?.map((category: ICategoryData) => (
//                         <div key={category.id} className="w-[98px] h-[137px] shadow-sm p-1  rounded-[4px]  border">
//                             <div className="w-full h-[90px] rounded-[4px]  border bg-[#FF930073] overflow-hidden p-1">
//                                 <img
//                                     src={
//                                         category.imageCarouselDetails?.length > 0 &&
//                                             category.imageCarouselDetails[0]?.image
//                                             ? category.imageCarouselDetails[0].image
//                                             : ""
//                                     }
//                                     alt={category.name}
//                                     className="w-full h-full object-contain "
//                                 />
//                             </div>
//                             <div className="w-full  flex flex-col items-center mt-0.5 font-bold ">
//                                 <span className="font-medium text-[8px] tracking-[0.1px]">
//                                     {category.name.length > 13 ? `${category.name.slice(0, 13)}...` : category.name}
//                                 </span>

//                                 <span className=" text-[6px] tracking-[0.1px] text-[#8096B7]">Count: {category.companyCount}</span>
//                                 <button className=" px-2 h-[13.11px] mt-[1px] w-full text-white text-[6px] font-semibold bg-[#0F172B] rounded-[4px]  transition-all duration-300"
//                                     onClick={() => handleItemClick(category?.id, category?.name)}
//                                 >
//                                     Purchase Now
//                                 </button>
//                             </div>
//                         </div>
//                     ))
//                 }

//             </div>
//         </div>
//     );
// };

// export default DemandProductSection;
