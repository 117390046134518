import { Link, useNavigate } from 'react-router-dom'

interface Data {
  id: string;
  image: string;
  itemCount: number;
  name: string;
}

interface Props {
  data: Data[];
}

const DiscountCard= ({ data }:Props) => {
  const navigate = useNavigate()

  return (
    <div className=' p-5 mt-2 mx-auto bg-[#F6FAFB] sm:bg-white w-[22rem] z-20 overflow-hidden  '>
      <div className='font-inter font-extrabold text-[1.1rem]'>Up to 05% off | Iron</div>
      <img src={data ? data?.[6]?.image : ""} alt="product image" className="w-[300px] h-[280px] md:mt-4 cursor-pointer " onClick={() => navigate(`/items/${data[6]?.id}/category/${data?.[6]?.name }`, { state: { name: data?.[6]?.id } })} />
      <Link to='/items'>
        <p className='text-[#007185] font-inter font-semibold mt-10'>Browse Our Iron Products</p>
      </Link>
    </div>
  )
}

export default DiscountCard