import React, { useRef, useState } from "react";
import { ProfileSidebar } from "./Components/ProfileSidebar";
import { ProfileHeader } from "./Components/ProfileHeader";
import useScrollToTop from "../../common/hooks/useScrollToTop";
import { BsThreeDotsVertical } from "react-icons/bs";
import BottomProfileMenu from "./Components/BottomProfileMenu";
import ProfileHeading from "./Components/ProfileHeading";

type Props = {
	children: React.ReactNode;
};

export const Profile = ({ children }: Props) => {
	const [isProfileMenu, setIsProfileMenu] = useState<boolean>(false);
	const elementRef = useRef<HTMLDivElement>(null);
	useScrollToTop(elementRef);

	const excludeHeaderPaths: string[] = ["/profile"];
	const excludeScrollPaths: string[] = ["/profile/edit"];

	const isHeaderExcluded = excludeHeaderPaths.includes(window.location.pathname);
	const isScrollExcluded = excludeScrollPaths.includes(window.location.pathname);

	const editProfile = window.location.pathname === "/profile/edit";

	const handleOpenProfileMenu = (): void => {
		setIsProfileMenu((prev: boolean) => !prev);
	};
	return (
		<div className="  w-full flex flex-col lg:flex-row h-full">
			<ProfileSidebar />
			{/* {
				editProfile || !isHeaderExcluded && <ProfileHeading />
			} */}
			<div
				ref={elementRef}
				className={`w-full relative no-scrollbar  overflow-y-scroll ${!isScrollExcluded ? " h-[80vh] " : " sm:h-screen  "
					}  `}>
				{
					!editProfile && <button
						onClick={handleOpenProfileMenu}
						className={`absolute right-4 top-4 sm:hidden z-30   flex justify-center items-center rounded-full   h-8 w-5 ${isHeaderExcluded ? "bg-white text-black" : " text-white"
							}`}>
						<BsThreeDotsVertical fontSize={"1.40rem"} />
					</button>
				}

				{children}
			</div>
			<BottomProfileMenu
				isOpen={isProfileMenu}
				isClose={handleOpenProfileMenu}
			/>
		</div>
	);
};
