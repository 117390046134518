import { PiUserSwitch } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import DefaultImage from "../../../assets/profile/DefualtProfile.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../Redux/store";
import { TbPhotoEdit } from "react-icons/tb";
import { ChangeEvent, useRef, useState } from "react";
import { useCreateImageURLMutation } from "../../../Redux/Api/Admin/Image";
import { useContractorAvtarMutation } from "../../../Redux/Api/Contractor";
import { AnimatePresence, motion } from "framer-motion"
import { toast } from "react-toastify";
import { MdCancel } from "react-icons/md";
interface ProfileImageProps {
    handleZoomProfile: () => void;
}

const styles = {
    boxShadowStyle: {
        boxShadow: "0px 0.5px 7px 0px #00000040",
    },
};


const ProfileImage = ({ handleZoomProfile }: ProfileImageProps) => {
    const [getImageURL, { data, isLoading: isUploading }] = useCreateImageURLMutation();
    const [mutateImage, { isLoading: isUpdating }] = useContractorAvtarMutation();
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const userDetails = useSelector((state: RootState) => state.Login.ContractorData);
    const navigate = useNavigate()
    const role = userDetails?.role;

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] || null;

        if (file) {
            setUploadedFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(file);
            setShowPopup(true);
        }
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setUploadedFile(null);
        setImagePreview(null);
    };

    const handleSubmit = async () => {
        if (uploadedFile) {
            const body = new FormData();
            body.append("image", uploadedFile);

            try {
                const response = await getImageURL(body).unwrap();
                mutateImage({ image: response[0] });
                toast.success("Profile image updated successfully!", {
                    position: "top-center",
                });
                handleClosePopup();
            } catch (error) {
                toast.error(
                    "Failed to update profile image. Please try again later.",
                    {
                        position: "top-center",
                    }
                );
                console.error("Error uploading image:", error);
            }
        }
    };

    const handleEditClick = () => {
        fileInputRef.current?.click();
    };
    return (
        <>
            <div className="w-full  flex flex-col ml-4 sm:ml-0 items-start sm:w-44 mr-2 ">
                <div
                    className=" flex justify-center  sm:ml-10 w-32 h-32 relative -mt-16 border-white bg-white rounded-full overflow-hidden "
                    style={styles.boxShadowStyle}>

                    {/* <span onClick={handleEditClick} className="bg-white absolute h-7 w-7 rounded-full flex  sm:hidden justify-center items-center bottom-5 right-5 p-1 cursor-pointer"><TbPhotoEdit fontSize={"1.40rem"} /></span> */}
                    <img
                        onClick={handleZoomProfile}
                        className="object-cover object-center h-32 w-32 border-4 rounded-full border-white sm:hover:scale-90 duration-300 cursor-pointer"
                        src={userDetails?.avatar || DefaultImage}
                        alt="Profile"
                    />
                    <input
                        id="profile-upload"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                    />
                </div>
                {
                    role === "admin" && (
                        <button
                            onClick={() => {
                                window.location.reload();
                                navigate("/admin");
                            }}
                            className="py-[6px] px-1  rounded-xl bg-[#5097A4] hidden sm:flex justify-center gap-1 text-white font-medium sm:ml-10 w-32 text-xs items-center mt-2 hover:scale-125 duration-300 border-2 border-white"
                            style={styles.boxShadowStyle}>
                            {" "}
                            <PiUserSwitch />
                            Go to Admin
                        </button>
                    )
                }
            </div >
            {/* Image Upload Popup */}
            {showPopup && (
                <AnimatePresence>
                    <motion.div
                        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}>
                        <motion.div
                            className=" bg-white rounded-lg text-center py-8 w-[250px] flex justify-center flex-col items-center relative "
                            initial={{ scale: 0.8 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.8 }}
                            transition={{ duration: 0.3 }}>
                            <span
                                onClick={handleClosePopup}
                                className="absolute right-2 top-2 bg-black z-50 rounded-full text-white cursor-pointer">
                                <MdCancel />
                            </span>
                            <h2 className="text-lg font-semibold mb-4">
                                Image Preview
                            </h2>
                            {imagePreview && (
                                <img
                                    src={imagePreview}
                                    alt="Uploaded Preview"
                                    className="w-32 h-32 border-4 border-[#5097A4] rounded-full mb-4 object-cover object-center"
                                />
                            )}
                            <div className="flex justify-center gap-3">
                                <button
                                    onClick={handleSubmit}
                                    className="bg-[#5097A4] hover:bg-[#5097A4] text-white text-[14px] font-bold py-1 px-3 rounded">
                                    {isUploading || isUpdating
                                        ? "Saving..."
                                        : "Save"}
                                </button>
                            </div>
                        </motion.div>
                    </motion.div>
                </AnimatePresence>
            )}
        </>
    )
}

export default ProfileImage
