import { ICategoryData } from "./types";
import bigcircle from "../../../assets/bigCircleMobile.png";
import { FaLock } from "react-icons/fa";
import Slider from "react-slick";
import "./MobileCarousel.css"
import { RootState } from "../../../Redux/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
interface ICarouselProps {
    data: ICategoryData[];
    isLoading: boolean;
}


const MobileCarousel = ({ data, isLoading }: ICarouselProps) => {
    const userDetails = useSelector((state: RootState) => state.Login.ContractorData);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };
    const navigate = useNavigate()
    const handleItemClick = (_id: string | null, name: string) => {
        const type = "category"
        if (_id) navigate(`/items/${_id}/${type}/${encodeURIComponent(name)}`, { state: { id: _id } });
        else toast.warning("Something went wrong! Please try again.");
    };

    return (
        <div className="w-full  h-[188pxpx] " >
            {isLoading ? (
                Array(1).fill(null)?.map((_, index: number) => (
                    <div
                        key={index}
                        className="relative flex-none w-full h-[188px] bg-gray-300 animate-pulse rounded-md"
                    >
                        <div className="absolute top-10 left-6 w-[120px] h-[20px] bg-gray-400 rounded-md"></div>
                        <div className="absolute top-14 left-6 w-[80px] h-[10px] bg-gray-400 rounded-md"></div>
                        <div className="absolute top-20 left-6 w-[60px] h-[12px] bg-gray-400 rounded-md"></div>
                        <div className="absolute top-28 left-6 w-[100px] h-[10px] bg-gray-400 rounded-md"></div>
                        <div className="absolute bottom-6 right-3 h-[130px] w-[140px] bg-gray-400 rounded-md"></div>
                        <div className="absolute bottom-3 right-3 h-[159.42px] w-[158px] bg-gray-200 rounded-full"></div>
                    </div>
                ))
            ) : (
                <Slider {...settings} className="custom-slider" >
                    {
                        data?.map((item: ICategoryData, index: number) => (
                            <div
                                onClick={() => handleItemClick(item?.id, item?.name)}
                                key={index} className="relative flex-none w-full h-[188px]">

                                <div className="mt-10 ml-7">
                                    <h1 className="w-[120px] text-wrap  font-medium text-[20px] font-bebas-neue leading-[24px] ">
                                        {item?.name}
                                    </h1>
                                    <div className="font-poppins mt-2 font-[300px] text-xs leading-[13.31px]">|
                                        {
                                            userDetails ? "Best Prices! " : "Login Here"
                                        }
                                    </div>
                                    <div className="font-poppins my-1 font-bold text-[12px] leading-[14.52px]">Available in</div>
                                    <p className="font-poppins font-medium text-[8px] leading-[9.68px] text-[#5C5C77] max-w-[140px] text-wrap">
                                        {item?.productNames && item.productNames.length > 0 && (
                                            item.productNames.length <= 3 ? (
                                                <div>
                                                    <span>{item.productNames.slice(0, 2).join(", ")}</span>
                                                    <span> and more</span>
                                                </div>
                                            ) : (
                                                <div>
                                                    <span>{item.productNames[0].slice(0, 12)}</span>
                                                    <span> and more</span>
                                                </div>
                                            )
                                        )}
                                    </p>

                                    <button className="w-fit text-nowrap mt-2 h-[15px] text-[4.4px] flex justify-center px-2 items-center gap-1 border-[0.5px] border-black rounded-sm">
                                        <FaLock />  Continue for Better Pricing
                                    </button>
                                </div>

                                <img
                                    src={
                                        item.imageCarouselDetails?.[0]?.image || ""
                                    }
                                    alt={item.name}
                                    className="absolute h-[130px] w-[140px] bottom-6 right-3 object-contain z-10"
                                />
                                <img
                                    src={bigcircle}
                                    alt="background"
                                    className="absolute h-[159.42px] w-[158px] bottom-3 right-3  object-contain z-0"
                                />

                            </div>
                        ))
                    }
                </Slider>

            )}
        </div>
    );
};

export default MobileCarousel;

