import { useGetContractorCategoryQuery } from "../../Redux/Api/Contractor";
import AssignedCategoryCard from "./components/AssignedCategoryCard";
import { RingLoader } from "react-spinners";
import NoData from "../../common/NoData";
import AssigendHeader from "./components/AssigendHeader";
import { useNavigate } from "react-router-dom";
import { setCategory, clearCategory } from "../../Redux/reducer/assignedRewardReducer"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootState } from "../../Redux/store";
import NoLoginAssignedRewards from "../../common/NoLoginAssignedRewards";
import CardLoading from "../../common/CardLoading";



interface ICategories {
    id: string;
    image?: string;
    name: string;
    itemCount: number;
}

const AssignedRewards = () => {
    const dispatch = useDispatch();
    const { refetch, data, isLoading: isCategoryLoading } = useGetContractorCategoryQuery();
    const navigate = useNavigate();
    const [visibleCount, setVisibleCount] = useState(8);
    const userDetails = useSelector((state: RootState) => state.Login.ContractorData);
    useEffect(() => {
        dispatch(clearCategory());
        localStorage.removeItem("assignedCategory");
    }, [dispatch]);
   

    const handleNavigateSingle = (category: ICategories) => {
        dispatch(setCategory(category));
        navigate("/category-item");
    };
    useEffect(() => {
        const updateVisibleCount = () => {
            if (window.innerWidth < 640) {
                setVisibleCount(12);
            } else {
                setVisibleCount(8);
            }
        };

        updateVisibleCount();
        window.addEventListener("resize", updateVisibleCount);

        return () => {
            window.removeEventListener("resize", updateVisibleCount);
        };
    }, []);

    const handleSeeMore = () => {
        const increment = window.innerWidth < 640 ? 12 : 8;
        setVisibleCount((prev) => prev + increment);
    };


    const categories = data?.categories || [];

    return (
        <>
            {
                userDetails ? <div className="font-inter bg-white py-5 sm:py-12 w-full sm:w-11/12 max-w-[1536px] m-auto">
                    <AssigendHeader />
                    {isCategoryLoading ? (
                        <div className="grid gap-3 sm:gap-8 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 px-4 sm:p-4 transition-all duration-500 ease-in-out transform">
                            {Array.from({ length: 8 }).map((_, index) => (
                                <CardLoading key={index} />
                            ))}
                        </div>
                    ) : (
                        data?.categories?.length > 0 ? (
                            <div className="grid gap-3 sm:gap-8 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 px-4 sm:p-4 transition-all duration-500 ease-in-out transform">
                                {categories.slice(0, visibleCount).map((category: ICategories) => (
                                    <AssignedCategoryCard
                                        key={category.id}
                                        id={category.id}
                                        name={category.name}
                                        itemCount={category.itemCount}
                                        image={category.image}
                                        onNavigate={() => handleNavigateSingle(category)}
                                    />
                                ))}
                            </div>
                        ) : (
                            <NoData messageText="No category has been assigned to your account yet." />
                        )
                    )}


                    {visibleCount < categories.length && (
                        <div className="flex justify-end mt-6 p-4">
                            <button
                                onClick={handleSeeMore}
                                className="bg-[#5097A4] bg-opacity-95 text-white px-3 sm:px-6 py-2 rounded-lg  text-xs sm:text-sm hover:bg-[#7ab4be] duration-300"
                            >
                                See More...
                            </button>
                        </div>
                    )}
                </div> : <NoLoginAssignedRewards />
            }

        </>
    );
};

export default AssignedRewards;
