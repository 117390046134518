import React, { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { useChangePasswordtOtpSendMutation } from "../../../Redux/Api/Signup";
import { toast } from "react-toastify";

const RequestOTP = () => {
    const [mutation, { data, isLoading, isSuccess, isError, error }] = useChangePasswordtOtpSendMutation()
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const navigate: NavigateFunction = useNavigate();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!phoneNumber || !/^\d{10}$/.test(phoneNumber)) {
            toast.warning("Please enter a valid 10-digit phone number.");
            return;
        }
        mutation({ phoneNumber });
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success(data?.message || "OTP sent successfully");
            const currentTime = Date.now();
            if (!localStorage.getItem("otpTimerStart2")) {
                localStorage.setItem("otpTimerStart2", currentTime.toString());
            }
            navigate("/forgot-password/verify-otp", {
                state: { phoneNumber },
            });
        }
        else if (isError) {
            toast.error(error?.data?.message || "An error occurred");
        }
    }, [isSuccess, isError]);

    return (
        <div className="relative font-poppins  sm:font-inter  flex flex-col items-center   bg-gradient-to-br from-[#5097A4] to-[#76B4C5] overflow-hidden px-4 py-20">
            <main
                id="content"
                role="main"
                className="w-full max-w-md px-6 py-10 bg-white rounded-2xl sm:shadow-lg">
                <div className="text-center">
                    <h1 className="text-[27px] sm:text-3xl font-bold text-gray-800 dark:text-white">
                        Send OTP
                    </h1>
                    <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                        Remember your password?{" "}
                        <Link
                            to={"/signup"}
                            className="text-blue-600 font-medium hover:underline dark:text-blue-400">
                            Login here
                        </Link>
                    </p>
                </div>
                <form className="mt-6" onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label
                            htmlFor="phoneNumber"
                            className="block text-sm font-medium text-gray-700 mb-2">
                            Phone Number
                        </label>
                        <div className="flex items-center border rounded-lg shadow-sm bg-gray-50">
                            <input
                                type="text"
                                id="phoneNumber"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                className="flex-1 px-4 py-3 text-sm focus:outline-none rounded-l-lg bg-transparent"
                                placeholder="Enter your number"
                            />
                        </div>
                        {error && (
                            <p className="mt-2 text-sm text-red-500">{error}</p>
                        )}
                    </div>
                    <button
                        type="submit"
                        disabled={isLoading}
                        className={`w-full inline-flex justify-center items-center rounded-lg bg-gradient-to-r from-[#5097A4] to-[#76B4C5] px-4 py-3 text-sm md:text-base font-semibold text-white shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5097A4] transition-all duration-200`}>
                        {isLoading ? (
                            <span className="flex items-center justify-center gap-2">
                                <FaSpinner className="animate-spin" />
                                Sending OTP...
                            </span>
                        ) : (
                            "Send OTP"
                        )}
                    </button>
                </form>
            </main>
        </div>
    );
};

export default RequestOTP;
