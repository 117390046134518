import shapeImage from "../../../assets/assets/singleProductShape.png";

interface ProductInfoTopProps {
    productName: string | undefined | null;
    isLoading: boolean;
}

const ProductInfoTop = ({ productName, isLoading }: ProductInfoTopProps) => {
    return (
        <div className="">
            {isLoading ? (
                // Skeleton Loader
                <div className="px-3 py-2 flex flex-col gap-2 animate-pulse">
                    <div className="flex items-start gap-2">
                        <div className="w-[55px] h-[16px] bg-gray-300 rounded"></div>
                        <div className="w-32 h-[16px] bg-gray-300 rounded"></div>
                    </div>
                    <div className="w-full h-[16px] bg-gray-300 rounded"></div>
                </div>
            ) : (
                // Content
                <div className="px-3 py-2 flex flex-col gap-2">
                    <div className="flex items-start gap-2 text-wrap">
                        <p
                            className="w-[55px] h-[16px] text-[11px] text-center flex items-center justify-center font-medium text-wrap"
                            style={{
                                backgroundImage: `url(${shapeImage})`,
                                backgroundSize: "contain",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                            }}
                        >
                            <span>Product</span>
                        </p>
                        <span className="text-[11px] font-medium">
                            {productName || "Unknown Product"}
                        </span>
                    </div>
                    <div className="font-medium text-[11px] text-gray-500">
                        <span className="text-black">50+ Ton</span> Quantity Bought in past
                        month
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductInfoTop;
