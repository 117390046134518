import { RotatingLines } from 'react-loader-spinner'

const ProfilePagesLoading = () => {
    return (
        <div className="flex items-center justify-center  h-[200px] sm:h-[400px] col-span-2">
            <RotatingLines
                strokeColor="#5097A4"
                strokeWidth="5"
                animationDuration="0.75"
                width="70"
                visible={true}
            />
        </div>
    )
}

export default ProfilePagesLoading