export const buttonStyle =
	"relative w-[128px] h-[25px] bg-[#FF9300] text-[10px] font-medium rounded-[4px] leading-[12.1px] text-[#2F2C2D] flex justify-center items-center gap-2 shadow-md overflow-hidden";

export const buttonBorderAnimation = `
  @keyframes borderMove {
    0% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
`;

export const gradientStyle = {
	background:
		"linear-gradient(180.46deg, #E1E7EF 0.4%, rgba(225, 231, 239, 0.75) 88.58%, rgba(225, 231, 239, 0) 99.6%)",
};

export const springIn = {
	hidden: { opacity: 0, scale: 0.8 },
	visible: {
		opacity: 1,
		scale: 1,
		transition: { type: "spring", stiffness: 50, damping: 10 },
	},
};

export const slideIn = {
	hidden: { opacity: 0, x: -100 },
	visible: {
		opacity: 1,
		x: 0,
		transition: { type: "spring", stiffness: 60, damping: 15 },
	},
};

export const staggerContainer = {
	hidden: {},
	visible: {
		transition: { staggerChildren: 0.25, delayChildren: 0.3 },
	},
};
export const gradientStyle2 = {
	background: "linear-gradient(180deg, #E1E7EE 0%, #FFFFFF 100%)",
};
