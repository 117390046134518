import React, { useState } from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { chawlaispat } from "../../Redux/apiRoutes";
import usePageMeta from "../../common/hooks/usePageMeta";
import { FiMapPin } from "react-icons/fi";
interface FormState {
	firstName?: string;
	lastName?: string;
	email?: string;
	phoneNumber: string;
	subject?: string;
	message?: string;
}

interface FormErrors {
	firstName?: string;
	phoneNumber?: string;
	message?: string;
}

const ContactUs = () => {
	const [formData, setFormData] = useState<FormState>({
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		subject: "",
		message: "",
	});

	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [loading, setLoading] = useState(true);

	// Handle form field changes with validation
	const handleInputChange = (
		event: React.ChangeEvent<
			HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
		>
	) => {
		const { name, value } = event.target;
		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}));

		// Real-time validation
		setFormErrors((prevErrors) => {
			const newErrors = { ...prevErrors };

			// Validate first name
			if (name === "firstName") {
				if (!value) {
					newErrors.firstName = "First name is required.";
				} else {
					delete newErrors.firstName;
				}
			}

			// Validate phone number
			if (name === "phoneNumber") {
				if (!value) {
					newErrors.phoneNumber = "Phone number is required.";
				} else if (!/^\d{10}$/.test(value)) {
					newErrors.phoneNumber = "Phone number must be 10 digits.";
				} else {
					delete newErrors.phoneNumber;
				}
			}

			// Validate message
			if (name === "message") {
				if (!value) {
					newErrors.message = "Message is required.";
				} else {
					delete newErrors.message;
				}
			}

			return newErrors;
		});
	};

	// Validate entire form on submit
	const validateForm = (): boolean => {
		const errors: FormErrors = {};
		if (!formData.firstName) errors.firstName = "First name is required.";
		if (!formData.phoneNumber)
			errors.phoneNumber = "Phone number is required.";
		else if (!/^\d{10}$/.test(formData.phoneNumber))
			errors.phoneNumber = "Phone number must be 10 digits.";
		if (!formData.message) errors.message = "Message is required.";

		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};

	// Handle form submission
	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsSubmitting(true);
		console.log(formData);
		if (validateForm()) {
			const { firstName, phoneNumber, message } = formData;
			const whatsappNumber = "919258294692";
			const encodedMessage = encodeURIComponent(
				`Name: ${firstName}\nPhone Number: ${phoneNumber}\nMessage: ${message}`
			);
			const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodedMessage}`;

			window.open(whatsappURL, "_blank");

			// Reset form and errors after successful submission
			setFormData({
				firstName: "",
				lastName: "",
				email: "",
				phoneNumber: "",
				subject: "",
				message: "",
			});
			setFormErrors({});
		}

		setIsSubmitting(false);
	};

	const title = "Contact Us | Building Material Supplier | Chawla Ispat";
	const description =
		"Get in touch with Chawla Ispat for any inquiries or support. We're here to help!";
	const canonicalUrl = chawlaispat;

	usePageMeta(title, description, canonicalUrl);

	const getBorderColor = (error: any) =>
		error ? "border-red-500" : "border-[#000000]";
	return (
		<div className="font-inter bg-white py-3">
			<div className="w-[98%] max-w-[1536px] mx-auto my-4 text-[#000000] relative">
				{/* Header Start */}
				<div className="flex flex-col items-center gap-3">
					<h1 className="text-3xl md:text-4xl font-bold leading-[48.41px]">
						Contact Us
					</h1>
					<p className="text-[#646464] font-medium text-lg text-wrap text-center px-2">
						Any question or remarks? Just write us a message!
					</p>
				</div>
				{/* Header End */}
				{/* Content Start */}
				<div className="relative flex flex-col md:flex-row my-6 bg-white h-auto md:h-full md:min-h-[431px] w-[95%] mx-auto gap-4">
					<div className="relative md:w-2/5 w-full shrink-0 overflow-hidden border-white border-[10px] rounded-2xl shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] h-[350px] md:h-auto">
						{loading && (
							<div className="w-full h-full flex items-center justify-center bg-gray-100 rounded-xl">
								<FiMapPin className="text-5xl text-gray-600 animate-pulse-scale" />
							</div>
						)}
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55842.052412769466!2d79.36254392857471!3d28.983569628882858!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a07fd9dbe0d7c7%3A0x3e982a8cc989e74a!2sChawla%20auto%20componant%20sidcul%20pantnagar%20rudrapur!5e0!3m2!1sen!2sin!4v1713431639918!5m2!1sen!2sin"
							width="100%"
							height="100%"
							className="border-0 rounded-2xl"
							loading="lazy"
							onLoad={() => setLoading(false)}></iframe>
					</div>

					{/* Form Section */}
					<div className="flex w-full flex-col rounded-2xl border-white border-[10px] shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] order-1 md:order-2">
						<form
							onSubmit={handleSubmit}
							className="w-full h-full rounded-2xl py-4 px-5 flex flex-col gap-3 text-sm bg-gradient-to-b from-[#5097A4] to-[rgb(192,235,231)]">
							<div className="flex flex-col md:flex-row w-full gap-5">
								<div className="w-full md:w-1/2 flex flex-col">
									<label
										htmlFor="firstName"
										className="font-medium">
										Enter Your Name
									</label>
									<input
										type="text"
										id="firstName"
										name="firstName"
										value={formData.firstName || ""}
										onChange={handleInputChange}
										className={`w-full py-2 border-b-[1.5px] bg-transparent focus:outline-none focus:border-b-[1.5px] ${getBorderColor(
											formErrors.firstName
										)}`}
									/>
									{formErrors.firstName && (
										<p className="text-red-500 text-xs mt-1">
											{formErrors.firstName}
										</p>
									)}
								</div>

								<div className="w-full md:w-1/2 flex flex-col">
									<label
										htmlFor="phoneNumber"
										className="font-medium">
										Phone Number
									</label>
									<input
										type="text"
										id="phoneNumber"
										name="phoneNumber"
										value={formData.phoneNumber || ""}
										onChange={handleInputChange}
										className={`w-full py-2 border-b-[1.5px] bg-transparent focus:outline-none focus:border-b-[1.5px] ${getBorderColor(
											formErrors.phoneNumber
										)}`}
									/>
									{formErrors.phoneNumber && (
										<p className="text-red-500 text-xs mt-1">
											{formErrors.phoneNumber}
										</p>
									)}
								</div>
							</div>

							<div className="flex flex-col gap-[14px] justify-between w-full">
								<label
									htmlFor="subject"
									className="font-medium">
									Select Subject
								</label>
								<div className="flex sm:flex-row flex-col sm:items-center gap-4">
									<div className="flex items-center sm:flex-col md:flex-row gap-1">
										<input
											type="radio"
											id="general"
											name="subject"
											value="General Enquiry"
											checked={
												formData.subject ===
												"General Enquiry"
											}
											onChange={handleInputChange}
											className="mr-1"
										/>
										<label
											htmlFor="general"
											className="text-[12px] leading-[20px]">
											General Enquiry
										</label>
									</div>
									<div className="flex items-center sm:flex-col md:flex-row gap-1">
										<input
											type="radio"
											id="personal"
											name="subject"
											value="Personal Enquiry"
											checked={
												formData.subject ===
												"Personal Enquiry"
											}
											onChange={handleInputChange}
											className="mr-1"
										/>
										<label
											htmlFor="personal"
											className="text-[12px] leading-[20px]">
											Personal Enquiry
										</label>
									</div>

									<div className="flex items-center sm:flex-col md:flex-row gap-1">
										<input
											type="radio"
											id="company"
											name="subject"
											value="Company Enquiry"
											checked={
												formData.subject ===
												"Company Enquiry"
											}
											onChange={handleInputChange}
											className="mr-1"
										/>
										<label
											htmlFor="company"
											className="text-[12px] leading-[20px]">
											Company Enquiry
										</label>
									</div>
								</div>
							</div>

							<div className="w-full flex flex-col rounded-2xl mt-3 shadow-sm">
								<label
									htmlFor="message"
									className="font-medium mb-1">
									Message
								</label>
								<textarea
									value={formData.message}
									onChange={handleInputChange}
									id="message"
									name="message"
									placeholder="Write your message...."
									rows={4}
									className={`w-full py-2 px-3 border-2 bg-transparent focus:outline-none focus:border-b-[1.5px] rounded-2xl bg-white ${getBorderColor(
										formErrors.message
									)}`}
								/>
								{formErrors.message && (
									<p className="text-red-500 text-xs mt-1">
										{formErrors.message}
									</p>
								)}
							</div>

							<div className="md:mt-[1rem] flex justify-center sm:justify-end md:px-0">
								<button
									type="submit"
									className="bg-white w-full md:w-[9.8rem] shadow-lg mt-1 px-4 py-3 rounded-md font-[500] text-[14px] leading-[20px] flex justify-center items-center gap-[7px]">
									<FaTelegramPlane className="text-[#5097A4]" />
									Send Message
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactUs;
