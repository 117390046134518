import { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
  matchPath,
} from "react-router-dom";
import Home from "./screens/Home";
import Items from "./screens/ItemsPage/Items";
import SingleProduct from "./screens/SingleProduct/SingleProduct";
import AboutUs from "./screens/AboutUs/Aboutus";
import WhatsappIcon from "./screens/extra/WhatsappIcon";
import ContactUs from "./screens/ContactUs/ContactUs";
import { Navigation } from "./screens/Components/Navigation";
import CategoryPage from "./screens/CategoryPage/category";
import { Loading } from "./common/Loader";
import CompanyPage from "./screens/CompanyPage/Company";
import Aside from "./common/Aside/Aside";
import { Tracking } from "./screens/tracking/Tracking";
import { TrackYourOrder } from "./screens/tracking/TrackYourOrder";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./Redux/store";
import Inventory from "./screens/admins/Inventory/Inventory";
import InventoryViewItem from "./screens/admins/Inventory/InventoryViewItem";
import { InventoryCompanyProduct } from "./screens/admins/Inventory/InventoryCompanyProduct";
import { MyProfile } from "./screens/Profile/Components/MyProfile";
import { Contractors } from "./screens/admins/contractors/Contractors";
import { useGetProfileQuery } from "./Redux/Api/Contractor";
import { ContractorData, LoginResponse } from "./Redux/reducer/LoginReducer";
import { ContractorsRequest } from "./screens/admins/contractors/ContractorsRequest";
import { Customer } from "./screens/admins/contractors/Customer";
import { RequestReward } from "./screens/admins/contractors/RequestReward";
import { RewardSytem } from "./screens/admins/contractors/RewardSytem";
import { Reward } from "./screens/admins/contractors/Rewards";
import PrivateRoute from "./screens/Components/protectedRoute";
import PublicRoute from "./screens/Components/PublicRoute";
import InventaoryItemPage from "./screens/admins/Inventory/inventaoryItemPage";
import useScrollToTop from "./common/hooks/useScrollToTop";
import Unauthorized from "./screens/Components/unauthorizedRoute";
import { SignupResponse } from "./screens/Unauthorized/HoldOnPage/signupResponse";
import { HoldOnPage } from "./screens/Unauthorized/HoldOnPage/HoldOnPage";
import SubItem from "./screens/subitem/subItem";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignupCheck from "./screens/Components/SignupCheck";
import { clearResults, clearSearchOnPages } from "./Redux/reducer/Filter";
import AnimatedDiv from "./screens/Login/Auth";
import { SearchPage } from "./common/SearchPage";
import Header from "./screens/Components/Header";
import Navbar from "./screens/Components/Navbar";
import Orders from "./screens/admins/Orders/order";
import CompleteOrders from "./screens/admins/Orders/completeOrders";
import OrderRequest from "./screens/admins/Orders/orderRequest";
import InventoryCompany from "./screens/admins/Inventory/InventoryCompany";
import { AdminLayout } from "./screens/admins/Inventory/components/AdminComponents/adminLayout";
import { Admin } from "./screens/admins/admin";
import { Profile } from "./screens/Profile/Profile";
import Footer from "./common/Footer";
import { CustomerEntry } from "./screens/Profile/Components/CustomerEntry";
import CompleteReferralOrderDetails from "./screens/admins/Orders/completeReferralOrderDetails";
import ReferralOrderDetails from "./screens/admins/Orders/requestReferralOrderDetails";
import { Query } from "./screens/Profile/Components/Query";
import AllContractorMessage from "./screens/admins/contractors/allContratorMessagePage";
import ContractorHistory from "./screens/admins/contractors/contractorHistory";
import ContractorCoinHistory from "./screens/Profile/Components/CoinHistory";
import "./config/i18nConfig";
import "./i18n";
import { Analytics } from "./screens/admins/analytics/Analytics";
import ErrorPage from "./screens/Error/Error";
import { Faq } from "./screens/Faqs/Faq";
import { CategoryDetails } from "./screens/admins/Inventory/CategoryDetails";
import { Rewards } from "./screens/Profile/Components/Rewards";
// import { AssignedRewards } from "./common/AssignedRewards";
import DeleteAccount from "./screens/DeleteAccount/DeleteAccount";
import SealAnimation from "./screens/Components/turnover2";

import ReactGA from "react-ga4";
import { ClaimedReward } from "./screens/admins/contractors/ClaimedRewrads";
import QuotationTracker from "./screens/Profile/Components/QuotationTracker";
import DeleteAccountResponse from "./screens/Unauthorized/DeleteAccountResponse";
import DeletedCheck from "./screens/Components/DeletedCheck";
import AssignedRewards from "./screens/assignedRewards/AssignedRewards";
import AssignedCategoryItem from "./screens/assignedRewards/assignedCategoryItem/AssignedCategoryItem";
import PrivacyPolicy from "./common/PrivacyPolicy";
import OTPForm from "./screens/Login/OTPForm";
import RequestOTP from "./screens/Login/forgetPassword/RequestOTP";
import ForgotPassword from "./screens/Login/forgetPassword/ForgetPassword";
import VerifyOTP from "./screens/Login/forgetPassword/VerifyOTP";
import Tour, { ReactourStep } from "reactour";
import Cart from "./screens/Cart/Cart";
import Customers from "./screens/admins/customers/Customers";
import EditProfileDetails from "./screens/Profile/Components/EditProfileDetails";

function App() {
  const dispatch = useDispatch();
  const [userState, setUserState] = useState<null | ContractorData>();
  const [isLoading, setIsLoading] = useState(true);
  const [hasRedirected, setHasRedirected] = useState(false);
  const { data: userResponse } = useGetProfileQuery();
  const navigate = useNavigate();
  const location = useLocation();

  // --------------------------------React JoyRide Start---------------------------------
  // const [isTourOpen, setIsTourOpen] = useState<boolean>(false);

  // const steps: ReactourStep[] = [
  //   {
  //     selector: "#mobileSideBar",
  //     content: "This is the global navigation bar. Use it to explore the app.",
  //   },
  //   {
  //     selector: "#companies",
  //     content: "Here you can find various companies listed. Click to learn more about each one.",
  //   },
  //   {
  //     selector: "#categories",
  //     content: "These are the categories available on the Home Page. Click on one to view related items.",
  //   },

  //   {
  //     selector: "#footer",
  //     content: "This is the footer. You can find additional links and information here.",
  //   },
  // ];

  // useEffect(() => {
  //   // Check if the screen width is below 768px (mobile devices)
  //   const isMobile = window.innerWidth <= 768;

  //   if (isMobile) {
  //     // Simulate loading for 1.5 seconds, then open the tour
  //     const timer = setTimeout(() => {
  //       setIsTourOpen(false); // Start the tour for mobile devices
  //     }, 1500);

  //     return () => clearTimeout(timer);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (isTourOpen) {
  //     document.body.style.overflow = "hidden"; // Disable scrolling
  //   } else {
  //     document.body.style.overflow = ""; // Re-enable scrolling
  //   }

  //   return () => {
  //     document.body.style.overflow = ""; // Cleanup on unmount
  //   };
  // }, [isTourOpen]);

  // --------------------------------React JoyRide End---------------------------------





  useEffect(() => {
    if (userResponse) {
      setUserState(userResponse);
      dispatch(LoginResponse(userResponse));
    }
  }, [userResponse, dispatch]);

  const userDetails = useSelector(
    (state: RootState) => state.Login.ContractorData
  );

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  ReactGA.initialize("G-Y3RHXRVJ4X");

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Custom Title",
    });
  });

  const role = userDetails?.role;
  const isFromSearch = useSelector(
    (state: RootState) => state.selection.isFromSearch
  );

  useEffect(() => {
    if (isFromSearch) {
      dispatch(clearSearchOnPages(location.pathname));
    }
  }, [location.pathname, isFromSearch, dispatch]);

  useEffect(() => {
    if (role === "admin" && !hasRedirected) {
      navigate("/admin");
      setHasRedirected(true);
    }
    setIsLoading(false);
  }, [role, navigate, hasRedirected]);

  // useEffect(() => {
  //   const handleRouteChange = (url: string) => {
  //     if (window.gtag) {
  //       window.gtag("config", "G-Y3RHX   npm install react-gaRVJ4X", {
  //         page_path: url,
  //       });
  //     }
  //   };
  // })


  useScrollToTop();

  const hideHeaderAndNavbar =
    location.pathname.startsWith("/admin") ||
    location.pathname === "/searchpage" ||
    location.pathname === "";



  const routes = [
    "/", "/admin", "/signup", "/unauthorized", "deleted", "/response", "/assignrewards", "/items/:id?/:type?/:name?", "/profile/entry",
    "/product/:id/:name", "/aboutus", "/contactus", "/categories", "/company", "/profile/Query", "/profile/rewards",
    "/order", "/tracking/:name", "/subItems", "/searchpage", "/profile", "/profile/history", "/profile/quotation-tracker", "/faq", "/delete-account", "/category-item", "/privacy-policy", "/forgot-password/request-otp", "/forgot-password", "/forgot-password/verify-otp", "/cart",
  ];

  const isNotRoute = routes.some(route => matchPath(route, location.pathname))


  // Google Tag Manager

  // useEffect(() => {
  //   const tagManagerArgs = {
  //     gtmId: 'GTM-Y3RHXRVJ4X',
  //   }
  //   TagManager.initialize(tagManagerArgs)
  // }, [])



  useEffect(() => {
    const defaultKeywords = "Chawla Ispat, steel products, bars, steel solutions, high-quality steel, competitive prices";
    const homeKeywords = "Chawla Ispat, Building material supplier, Construction material suppliers";

    let keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (!keywordsMetaTag) {
      keywordsMetaTag = document.createElement('meta');
      keywordsMetaTag.setAttribute('name', 'keywords');
      document.head.appendChild(keywordsMetaTag);
    }
    if (location.pathname === '/') {
      keywordsMetaTag.setAttribute("content", homeKeywords);
    } else {
      keywordsMetaTag.setAttribute("content", defaultKeywords);
    }
  }, [location]);

  useEffect(() => {
    if (!location.pathname.startsWith("/items")) {
      dispatch(clearResults())
    }

  }, [location.pathname]);



  const excludeNavigationRoutes = [
    "/searchpage",
    "/product/:name",
    "/profile/edit",
    "/otp-verification"
  ];

  const isExcluded = excludeNavigationRoutes.some((route) =>
    matchPath(route, location.pathname)
  );


  return (
    <div className="bg-white flex flex-col min-h-screen">
      {/* <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      /> */}


      <div>
        <WhatsappIcon />
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="flex flex-col font-inter flex-grow">
            {!hideHeaderAndNavbar && isNotRoute && <Header />}
            {!hideHeaderAndNavbar && isNotRoute && <Navbar />}
            {/* <BackButton /> */}
            <div className="flex-grow">
              <Routes>
                <Route element={<PrivateRoute roles={["admin"]} />}>
                  <Route
                    path="/admin"
                    element={
                      <Aside>
                        <Admin />
                      </Aside>
                    }
                  />
                  <Route
                    path="/admin/category"
                    element={
                      <AdminLayout>
                        <Inventory />
                      </AdminLayout>
                    }
                  />
                  <Route
                    path="/admin/category/view/:id"
                    element={
                      <AdminLayout>
                        <InventoryViewItem />
                      </AdminLayout>
                    }
                  />
                  <Route
                    path="/admin/category/item/:id"
                    element={
                      <AdminLayout>
                        <InventaoryItemPage />
                      </AdminLayout>
                    }
                  />
                  <Route
                    path="/admin/categoryDetails/:id"
                    element={
                      <AdminLayout>
                        <CategoryDetails />
                      </AdminLayout>
                    }
                  />
                  <Route
                    path="/admin/company"
                    element={
                      <AdminLayout>
                        <InventoryCompany />
                      </AdminLayout>
                    }
                  />
                  <Route
                    path="/admin/company/:name"
                    element={
                      <AdminLayout>
                        <InventoryCompanyProduct />
                      </AdminLayout>
                    }
                  />
                  {/* <Route
                    path="/admin/customers"
                    element={
                      <AdminLayout>
                        <Customers />
                      </AdminLayout>
                    }
                  /> */}
                  <Route
                    path="/admin/contractors"
                    element={
                      <AdminLayout>
                        <Contractors />
                      </AdminLayout>
                    }
                  />
                  <Route
                    path="/admin/contractors/request"
                    element={
                      <AdminLayout>
                        <ContractorsRequest />
                      </AdminLayout>
                    }
                  />
                  <Route
                    path="/admin/contractors/profile/:id"
                    element={
                      <AdminLayout>
                        <Customer />
                      </AdminLayout>
                    }
                  />
                  <Route
                    path="/admin/contractors/delete"
                    element={
                      <AdminLayout>
                        <ContractorHistory />
                      </AdminLayout>
                    }
                  />

                  <Route
                    path="/admin/reward/request"
                    element={
                      <AdminLayout>
                        <RequestReward />
                      </AdminLayout>
                    }
                  />
                  <Route
                    path="/admin/reward/claim"
                    element={
                      <AdminLayout>
                        <ClaimedReward />
                      </AdminLayout>
                    }
                  />
                  <Route
                    path="/admin/reward/rewardSystem"
                    element={
                      <AdminLayout>
                        <RewardSytem />
                      </AdminLayout>
                    }
                  />
                  <Route
                    path="/admin/reward"
                    element={
                      <AdminLayout>
                        <Reward />
                      </AdminLayout>
                    }
                  />
                  <Route
                    path="/admin/analytics"
                    element={
                      <AdminLayout>
                        <Analytics />
                      </AdminLayout>
                    }
                  />

                  <Route
                    path="/admin/orders"
                    element={
                      <Aside>
                        <Orders />
                      </Aside>
                    }
                  >
                    <Route index element={<Navigate to="request" />} />
                    <Route path="request" element={<OrderRequest />} />
                    <Route
                      path="request/:id"
                      element={<ReferralOrderDetails />}
                    />
                    <Route path="complete" element={<CompleteOrders />} />
                    <Route
                      path="complete/:id"
                      element={<CompleteReferralOrderDetails />}
                    />
                  </Route>

                  <Route
                    path="/admin/message"
                    element={
                      <Aside>
                        <AllContractorMessage />
                      </Aside>
                    }
                  />
                </Route>

                <Route
                  element={<PrivateRoute roles={["admin", "contractor"]} />}
                >
                  <Route
                    path="/profile"
                    element={
                      <Profile>
                        <MyProfile />
                      </Profile>
                    }
                  />
                  <Route
                    path="/profile/edit"
                    element={
                      <Profile>
                        <EditProfileDetails />
                      </Profile>
                    }
                  />
                  <Route
                    path="/profile/history"
                    element={
                      <Profile>
                        <ContractorCoinHistory />{" "}
                      </Profile>
                    }
                  />
                  <Route
                    path="/profile/rewards"
                    element={
                      <Profile>
                        <Rewards />
                      </Profile>
                    }
                  />
                  <Route
                    path="/profile/entry"
                    element={
                      <Profile>
                        <CustomerEntry />
                      </Profile>
                    }
                  />
                  <Route
                    path="/profile/Query"
                    element={
                      <Profile>
                        <Query />
                      </Profile>
                    }
                  />
                  <Route
                    path="/profile/quotation-tracker"
                    element={
                      <Profile>
                        <QuotationTracker />
                      </Profile>
                    }
                  />
                  <Route
                    path="/profile/rewards"
                    element={
                      <Profile>
                        <Rewards />
                      </Profile>
                    }
                  />
                </Route>
                <Route element={<DeletedCheck />}>
                  <Route path="/deleted" element={<DeleteAccountResponse />} />
                </Route>

                <Route element={<Unauthorized />}>
                  <Route path="/unauthorized" element={<HoldOnPage />} />
                </Route>

                {/* <Route element={<SignupCheck />}>
                  <Route path="/response" element={<SignupResponse />} />
                </Route> */}

                <Route element={<PublicRoute />}>
                  <Route path="/signup" element={<AnimatedDiv />} />
                  <Route path="/otp-verification" element={<OTPForm />} />
                  {/* Forget Password */}
                  <Route path="/forgot-password/request-otp" element={<RequestOTP />} />
                  <Route path="/forgot-password/verify-otp" element={<VerifyOTP />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                </Route>

                <Route path="/" element={<Home />} />
                <Route path="/assignrewards" element={<AssignedRewards />} />
                <Route path="/category-item" element={<AssignedCategoryItem />} />
                <Route path="/items/:id?/:type?/:name?" element={<Items />} />
                {/* <Route path="/cart" element={<Cart />} /> */}
                {/* <Route path="/items" element={<Items />} /> */}
                <Route path="/product/:id/:name" element={<SingleProduct />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/categories" element={<CategoryPage />} />
                <Route path="/company" element={<CompanyPage />} />
                <Route path="/order" element={<Tracking />} />
                <Route path="/tracking/:name" element={<TrackYourOrder />} />
                <Route path="/subItems" element={<SubItem />} />
                <Route path="/searchpage" element={<SearchPage />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/delete-account" element={<DeleteAccount />} />
                <Route path="/open" element={<CategoryDetails />} />
                <Route path="/turnOver" element={<SealAnimation />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </div>
            {!location.pathname.startsWith("/admin") && isNotRoute && (
              <Footer />
            )}
          </div>
          {/* {location.pathname !== "/searchpage" && location.pathname !== "/product" && <Navigation />} */}
          {!isExcluded && <Navigation />}
        </>
      )}
      <ToastContainer
        position="top-center"
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
