import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";
import { GrLanguage } from "react-icons/gr";
import footerImg from "../assets/assets/footerImg.png";
import { FaSquareXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { ImMobile } from "react-icons/im";
interface IMobileFooter {
    viewDigits: string[];
    openGmail: () => void
}

const isWebView = (): boolean => {
    const userAgent = navigator.userAgent || (window as any).opera;
    const isAndroidWebView = /\bwv\b/.test(userAgent) || /Android.*Version\/[0-9.]+/.test(userAgent);
    const isIOSWebView = /iPhone|iPod|iPad.*AppleWebKit(?!.*Safari)/.test(userAgent);

    return isAndroidWebView || isIOSWebView;
};

const MobileFooter = ({ viewDigits, openGmail }: IMobileFooter) => {

    const webViewStatus = isWebView();
    return (
        <footer id="footer" className="w-full sm:hidden font-poppins">
    


            {/* Main Footer Section */}
            <div className="w-full bg-turkishBlue-default text-white pt-5 h-[230px] flex flex-wrap justify-around">
                {/* Quick Links */}
                <div className="text-start ">
                    <div className="w-full">
                        <span className="font-[900] text-[15px] leading-[1.5px]">
                            QUICK LINKS
                        </span>
                        <div className="flex flex-col gap-2 mt-1.5 pl-1">
                            <Link
                                className="text-[10px] leading-[12.1px] font-medium"
                                to={"/aboutus"}
                            >
                                Why Lohawalla?
                            </Link>
                            <Link
                                className="text-[10px] leading-[12.1px] font-medium"
                                to={"/items"}
                            >
                                Our products
                            </Link>
                            <Link
                                className="text-[10px] leading-[12.1px] font-medium"
                                to={""}
                            >
                                Wish list
                            </Link>
                            <Link
                                className="text-[10px] leading-[12.1px] font-medium"
                                to={"#"}
                            >
                                My Order
                            </Link>
                        </div>
                    </div>
                    <div className="mt-4 w-full">
                        <span className="font-[900] text-[15px] leading-[1.5px]">
                            CONTACT US
                        </span>
                        <div className="flex flex-col gap-2 mt-1.5 pl-1">
                            <button type="button"
                                onClick={openGmail}
                                className="text-[10px] leading-[12.1px] font-medium"

                            >
                                contact@lohawalla.com
                            </button>
                        </div>
                    </div>
                </div>
                {/* Resources */}
                <div className="text-start ">
                    <div className="w-full">
                        <span className="font-[900] text-[15px] leading-[1.5px]">
                            RESOURCES
                        </span>
                        <div className="flex flex-col gap-2 mt-1.5 pl-1">
                            <Link
                                className="text-[10px] leading-[12.1px] font-medium"
                                to={"/"}
                            >
                                Home
                            </Link>
                            <Link
                                className="text-[10px] leading-[12.1px] font-medium"
                                to={"/categories"}
                            >
                                Categories
                            </Link>
                            <Link
                                className="text-[10px] leading-[12.1px] font-medium"
                                to={"/items"}
                            >
                                Products
                            </Link>
                            <Link
                                className="text-[10px] leading-[12.1px] font-medium"
                                to={"/aboutus"}
                            >
                                About Us
                            </Link>
                        </div>
                    </div>
                    <div className="mt-4">
                        <span className="font-[900] text-[15px] leading-[1.5px]">
                            COMPANY
                        </span>
                        <div className="flex flex-col gap-2 mt-1.5 pl-1">
                            <Link
                                className="text-[10px] leading-[12.1px] font-medium"
                                to={"/aboutus"}
                            >
                                Careers
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-6 bg-[#0C141F] w-full">
              {/* View App */}
                {
                    !webViewStatus && <div className="flex flex-col items-center justify-center text-white gap-2 mb-5 pb-3 ">
                        <p className="flex items-center gap-1">
                            <ImMobile />   <span className="text-sm">Experience the Chawla Mobile app</span>
                        </p>
                        <div className="">
                            <a
                                className="playstore css-10vld1x"
                                href="https://play.google.com/store/apps/details?id=com.chawlaispat&pcampaignid=web_share"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={122}
                                    height={36}
                                    viewBox="0 0 135 40"
                                >
                                    <title>Google Play Store</title>
                                    <path d="M130 40H5c-2.8 0-5-2.2-5-5V5c0-2.8 2.2-5 5-5h125c2.8 0 5 2.2 5 5v30c0 2.8-2.2 5-5 5z" />
                                    <path
                                        d="M130 .8c2.3 0 4.2 1.9 4.2 4.2v30c0 2.3-1.9 4.2-4.2 4.2H5C2.7 39.2.8 37.3.8 35V5C.8 2.7 2.7.8 5 .8h125m0-.8H5C2.2 0 0 2.3 0 5v30c0 2.8 2.2 5 5 5h125c2.8 0 5-2.2 5-5V5c0-2.7-2.2-5-5-5z"
                                        fill="#a6a6a6"
                                    />
                                    <path
                                        d="M47.4 10.2c0 .8-.2 1.5-.7 2-.6.6-1.3.9-2.2.9s-1.6-.3-2.2-.9c-.6-.6-.9-1.3-.9-2.2s.3-1.6.9-2.2c.6-.6 1.3-.9 2.2-.9.4 0 .8.1 1.2.3.4.2.7.4.9.7l-.5.5c-.4-.5-.9-.7-1.6-.7-.6 0-1.2.2-1.6.7-.5.4-.7 1-.7 1.7s.2 1.3.7 1.7 1 .7 1.6.7c.7 0 1.2-.2 1.7-.7.3-.3.5-.7.5-1.2h-2.2v-.8h2.9v.4zM52 7.7h-2.7v1.9h2.5v.7h-2.5v1.9H52v.8h-3.5V7H52v.7zm3.3 5.3h-.8V7.7h-1.7V7H57v.7h-1.7V13zm4.6 0V7h.8v6h-.8zm4.2 0h-.8V7.7h-1.7V7h4.1v.7H64l.1 5.3zm9.5-.8c-.6.6-1.3.9-2.2.9-.9 0-1.6-.3-2.2-.9-.6-.6-.9-1.3-.9-2.2s.3-1.6.9-2.2 1.3-.9 2.2-.9c.9 0 1.6.3 2.2.9s.9 1.3.9 2.2-.3 1.6-.9 2.2zm-3.8-.5c.4.4 1 .7 1.6.7s1.2-.2 1.6-.7c.4-.4.7-1 .7-1.7s-.2-1.3-.7-1.7c-.4-.4-1-.7-1.6-.7s-1.2.2-1.6.7c-.4.4-.7 1-.7 1.7s.2 1.3.7 1.7zm5.8 1.3V7h.9l2.9 4.7V7h.8v6h-.8l-3.1-4.9V13h-.7z"
                                        fill="#fff"
                                        stroke="#fff"
                                        strokeWidth=".2"
                                        strokeMiterlimit={10}
                                    />
                                    <path
                                        d="M68.1 21.8c-2.4 0-4.3 1.8-4.3 4.3 0 2.4 1.9 4.3 4.3 4.3s4.3-1.8 4.3-4.3c0-2.6-1.9-4.3-4.3-4.3zm0 6.8c-1.3 0-2.4-1.1-2.4-2.6s1.1-2.6 2.4-2.6 2.4 1 2.4 2.6c0 1.5-1.1 2.6-2.4 2.6zm-9.3-6.8c-2.4 0-4.3 1.8-4.3 4.3 0 2.4 1.9 4.3 4.3 4.3s4.3-1.8 4.3-4.3c0-2.6-1.9-4.3-4.3-4.3zm0 6.8c-1.3 0-2.4-1.1-2.4-2.6s1.1-2.6 2.4-2.6c1.3 0 2.4 1 2.4 2.6 0 1.5-1.1 2.6-2.4 2.6zm-11.1-5.5v1.8H52c-.1 1-.5 1.8-1 2.3-.6.6-1.6 1.3-3.3 1.3-2.7 0-4.7-2.1-4.7-4.8s2.1-4.8 4.7-4.8c1.4 0 2.5.6 3.3 1.3l1.3-1.3c-1.1-1-2.5-1.8-4.5-1.8-3.6 0-6.7 3-6.7 6.6s3.1 6.6 6.7 6.6c2 0 3.4-.6 4.6-1.9 1.2-1.2 1.6-2.9 1.6-4.2 0-.4 0-.8-.1-1.1h-6.2zm45.4 1.4c-.4-1-1.4-2.7-3.6-2.7s-4 1.7-4 4.3c0 2.4 1.8 4.3 4.2 4.3 1.9 0 3.1-1.2 3.5-1.9l-1.4-1c-.5.7-1.1 1.2-2.1 1.2s-1.6-.4-2.1-1.3l5.7-2.4-.2-.5zm-5.8 1.4c0-1.6 1.3-2.5 2.2-2.5.7 0 1.4.4 1.6.9l-3.8 1.6zM82.6 30h1.9V17.5h-1.9V30zm-3-7.3c-.5-.5-1.3-1-2.3-1-2.1 0-4.1 1.9-4.1 4.3s1.9 4.2 4.1 4.2c1 0 1.8-.5 2.2-1h.1v.6c0 1.6-.9 2.5-2.3 2.5-1.1 0-1.9-.8-2.1-1.5l-1.6.7c.5 1.1 1.7 2.5 3.8 2.5 2.2 0 4-1.3 4-4.4V22h-1.8v.7zm-2.2 5.9c-1.3 0-2.4-1.1-2.4-2.6s1.1-2.6 2.4-2.6 2.3 1.1 2.3 2.6-1 2.6-2.3 2.6zm24.4-11.1h-4.5V30h1.9v-4.7h2.6c2.1 0 4.1-1.5 4.1-3.9s-2-3.9-4.1-3.9zm.1 6h-2.7v-4.3h2.7c1.4 0 2.2 1.2 2.2 2.1-.1 1.1-.9 2.2-2.2 2.2zm11.5-1.8c-1.4 0-2.8.6-3.3 1.9l1.7.7c.4-.7 1-.9 1.7-.9 1 0 1.9.6 2 1.6v.1c-.3-.2-1.1-.5-1.9-.5-1.8 0-3.6 1-3.6 2.8 0 1.7 1.5 2.8 3.1 2.8 1.3 0 1.9-.6 2.4-1.2h.1v1h1.8v-4.8c-.2-2.2-1.9-3.5-4-3.5zm-.2 6.9c-.6 0-1.5-.3-1.5-1.1 0-1 1.1-1.3 2-1.3.8 0 1.2.2 1.7.4-.2 1.2-1.2 2-2.2 2zm10.5-6.6l-2.1 5.4h-.1l-2.2-5.4h-2l3.3 7.6-1.9 4.2h1.9l5.1-11.8h-2zm-16.8 8h1.9V17.5h-1.9V30z"
                                        fill="#fff"
                                    />
                                    <linearGradient
                                        id="gpA"
                                        gradientUnits="userSpaceOnUse"
                                        x1="21.795"
                                        y1="-152.295"
                                        x2="5.012"
                                        y2="-135.513"
                                        gradientTransform="translate(0 161)"
                                    >
                                        <stop offset={0} stopColor="#00a0ff" />
                                        <stop offset=".007" stopColor="#00a1ff" />
                                        <stop offset=".26" stopColor="#00beff" />
                                        <stop offset=".512" stopColor="#00d2ff" />
                                        <stop offset=".76" stopColor="#00dfff" />
                                        <stop offset={1} stopColor="#00e3ff" />
                                    </linearGradient>
                                    <path
                                        d="M10.4 7.5c-.3.3-.4.8-.4 1.4V31c0 .6.2 1.1.5 1.4l.1.1L23 20.1v-.2L10.4 7.5z"
                                        fill="url(#gpA)"
                                    />
                                    <linearGradient
                                        id="gpB"
                                        gradientUnits="userSpaceOnUse"
                                        x1="33.834"
                                        y1="-140.95"
                                        x2="9.637"
                                        y2="-140.95"
                                        gradientTransform="translate(0 161)"
                                    >
                                        <stop offset={0} stopColor="#ffe000" />
                                        <stop offset=".409" stopColor="#ffbd00" />
                                        <stop offset=".775" stopColor="orange" />
                                        <stop offset={1} stopColor="#ff9c00" />
                                    </linearGradient>
                                    <path
                                        d="M27 24.3l-4.1-4.1v-.3l4.1-4.1.1.1 4.9 2.8c1.4.8 1.4 2.1 0 2.9l-5 2.7z"
                                        fill="url(#gpB)"
                                    />
                                    <linearGradient
                                        id="gpC"
                                        gradientUnits="userSpaceOnUse"
                                        x1="24.816"
                                        y1="-138.715"
                                        x2="2.058"
                                        y2="-115.957"
                                        gradientTransform="translate(0 161)"
                                    >
                                        <stop offset={0} stopColor="#ff3a44" />
                                        <stop offset={1} stopColor="#c31162" />
                                    </linearGradient>
                                    <path
                                        d="M27.1 24.2L22.9 20 10.4 32.5c.5.5 1.2.5 2.1.1l14.6-8.4"
                                        fill="url(#gpC)"
                                    />
                                    <linearGradient
                                        id="gpD"
                                        gradientUnits="userSpaceOnUse"
                                        x1="7.261"
                                        y1="-160.788"
                                        x2="17.424"
                                        y2="-150.625"
                                        gradientTransform="translate(0 161)"
                                    >
                                        <stop offset={0} stopColor="#32a071" />
                                        <stop offset=".069" stopColor="#2da771" />
                                        <stop offset=".476" stopColor="#15cf74" />
                                        <stop offset=".801" stopColor="#06e775" />
                                        <stop offset={1} stopColor="#00f076" />
                                    </linearGradient>
                                    <path
                                        d="M27.1 15.8L12.5 7.5c-.9-.5-1.6-.4-2.1.1L22.9 20l4.2-4.2z"
                                        fill="url(#gpD)"
                                    />
                                    <path
                                        d="M27 24.1l-14.5 8.2c-.8.5-1.5.4-2 0l-.1.1.1.1c.5.4 1.2.5 2 0L27 24.1z"
                                        opacity=".2"
                                    />
                                    <path
                                        d="M10.4 32.3c-.3-.3-.4-.8-.4-1.4v.1c0 .6.2 1.1.5 1.4v-.1h-.1zm21.6-11l-5 2.8.1.1 4.9-2.8c.7-.4 1-.9 1-1.4 0 .5-.4.9-1 1.3z"
                                        opacity=".12"
                                    />
                                    <path
                                        d="M12.5 7.6L32 18.7c.6.4 1 .8 1 1.3 0-.5-.3-1-1-1.4l-19.5-11c-1.4-.9-2.5-.3-2.5 1.3V9c0-1.5 1.1-2.2 2.5-1.4z"
                                        opacity=".25"
                                        fill="#fff"
                                    />
                                </svg>
                            </a>
                        </div>
                    </div>
                }
                <div className="flex flex-col justify-center gap-3">
                    {/* Social Icons */}
                    <div className="flex justify-center text-white gap-2.5">
                        <a
                            href="https://twitter.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Twitter"
                            className="hover:text-gray-300 transition-colors">
                            <FaSquareXTwitter fontSize="1.22rem" />
                        </a>
                        <a
                            href="https://www.instagram.com/chawlaispat?igsh=MWgwOWR2NjZlODVhdg=="
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Instagram"
                            className="hover:text-gray-300 transition-colors">
                            <AiFillInstagram fontSize="1.4rem" />
                        </a>
                        <a
                            href="https://www.facebook.com/chawlaispatrdp/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Facebook"
                            className="hover:text-gray-300 transition-colors">
                            <AiFillFacebook fontSize="1.4rem" />
                        </a>
                    </div>

                    {/* Language Selector */}
                    <div className="flex justify-center items-center gap-2 text-white/65 text-[12px] font-medium">
                        <button
                            aria-label="Change Language"
                            className="hover:text-gray-300 transition-colors">
                            <GrLanguage fontSize="1.3rem" />
                        </button>
                        <span>English</span>
                    </div>

                    {/* Sign-in Link */}
                    <div className="text-center">
                        <a
                            href="/login"
                            className="text-white/65 hover:text-white transition-colors text-[12px] font-medium">
                            Already a Customer? Sign in
                        </a>
                    </div>

                    {/* Visitor Counter */}
                    <div className="flex justify-center items-center gap-3 mt-2">
                        <p className="text-white/65 text-[10px] font-medium">
                            Number of Visitors:
                        </p>
                        <div className="flex gap-1">
                            {viewDigits.map((digit, index) => (
                                <div
                                    key={index}
                                    className="w-[11px] h-[15px] rounded-[2px] flex items-center justify-center relative"
                                    style={{
                                        background:
                                            "linear-gradient(to bottom, #FFFFFF 50%, #7DD9FF 50%)",
                                    }}>
                                    <span className="text-[10px]  text-black">
                                        {digit}
                                    </span>
                                    <span className="w-[1px] h-[3px] bg-black absolute left-0 top-1/2 transform -translate-y-1/2"></span>
                                    <span className="w-[1px] h-[3px] bg-black absolute right-0 top-1/2 transform -translate-y-1/2"></span>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Footer */}
                    <div className="flex justify-center items-center gap-2 text-white text-[7px]">
                        <img
                            src={footerImg}
                            alt="Footer Logo"
                            className="h-[19px] w-[20px]"
                        />
                        <div className=" font-medium text-[8px] leading-[10px]">
                            <p>
                                2023 Chawla Ispat Private Limited. All rights
                                reserved.
                            </p>
                            <div className="flex gap-1">
                                <a href="#" className="underline">
                                    Terms of Service
                                </a>
                                <span>|</span>
                                <Link to={"/privacy-policy"} className="underline">
                                    Privacy Policy
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default MobileFooter;
