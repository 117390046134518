import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import CustomModal from "../../../../utils/CustomModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/store";
import { useContractorChangePasswordMutation } from "../../../../Redux/Api/Contractor";

type ChangePasswordProps = {
    isOpen: boolean,
    setIsOpen: (val: boolean) => void;
};

const ChangePassword = ({ isOpen, setIsOpen }: ChangePasswordProps) => {
    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    const [contractorChangePassword, { data: changePasswordResponse, error, isLoading }] = useContractorChangePasswordMutation();

    const userDetails = useSelector((state: RootState) => state.Login.ContractorData);


    const onClose = (): void => {
        setIsOpen(false);
        resetForm()

    };

    const resetForm = () => {
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
    };
    const validateForm = () => {
        if (!oldPassword) return showToastAndReturn("Old Password is required");
        if (!newPassword) return showToastAndReturn("New Password is required");
        if (newPassword.length < 6) return showToastAndReturn("New Password must be at least 6 characters");
        if (!confirmPassword) return showToastAndReturn("Confirm Password is required");
        if (newPassword !== confirmPassword) return showToastAndReturn("Passwords do not match");
        return true;
    };

    const showToastAndReturn = (message: string) => {
        toast.error(message);
        return false;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
            contractorChangePassword({
                oldPassword,
                newPassword,
            });
        }
    };

    useEffect(() => {
        if (changePasswordResponse) {
            if (changePasswordResponse.success) {
                toast.success(changePasswordResponse.message || "Password changed successfully!");
                setIsOpen(false);
                resetForm()
            } else {
                toast.error(changePasswordResponse.message || "Failed to change password");
            }
        }

        if (error) {
            const errorMessage = error?.data?.message || "An error occurred";
            toast.error(errorMessage);
        }
    }, [changePasswordResponse, error, setIsOpen]);




    return (
        <CustomModal isOpen={isOpen} onClose={onClose} title={""} width={"md:max-w-[290px]"}>
            <div className="flex flex-col gap-y-1 items-center">
                <div className="h-16 w-16 rounded-full border-[4px] overflow-hidden border-[#47848E] flex justify-center items-center ">
                    {
                        userDetails?.avatar ?
                            <img
                                src={userDetails.avatar}
                                alt=""
                                className="w-16 h-16 rounded-full object-cover  object-center"
                            /> : <span className="text-4xl w-16 h-16 rounded-full flex justify-center items-center font-semibold object-cover text-[#47848E]">{userDetails?.name.charAt(0).toUpperCase()}</span>
                    }
                </div>
                <span className="font-bold">{userDetails?.name}</span>
                <div className="flex text-xl font-semibold mb-1">Reset Password</div>
                <form className="flex flex-col w-full justify-between px-5 shadow-[0px_0px_20px_-8px_#47848E] p-4 rounded-lg" onSubmit={handleSubmit}>

                    {/* Old Password Input */}
                    <div className="mb-2 w-full flex flex-col text-xs">
                        <label className="text-xs font-[400] mb-2" htmlFor="oldPassword">
                            Old Password <span className="text-red-500">*</span>
                        </label>
                        <div className="relative">
                            <input
                                type={showOldPassword ? "text" : "password"}
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                className="rounded-lg border-[1.5px] border-[#5097A4] w-full pl-3 py-2 h-full focus:border-[#5097A4] outline-none "
                                placeholder="Enter old password"
                            />
                            <button
                                type="button"
                                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600"
                                onClick={() => setShowOldPassword(!showOldPassword)}
                            >
                                {showOldPassword ? <FaEye /> : <FaEyeSlash />}
                            </button>
                        </div>
                    </div>

                    {/* New Password Input */}
                    <div className="mb-2 w-full flex flex-col text-xs">
                        <label className="text-xs font-[400] mb-2" htmlFor="newPassword">
                            New Password <span className="text-red-500">*</span>
                        </label>
                        <div className="relative">
                            <input
                                type={showNewPassword ? "text" : "password"}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className="rounded-lg border-[1.5px] border-[#5097A4] w-full pl-3 py-2 h-full focus:border-[#5097A4] outline-none "
                                placeholder="Enter new password"
                            />
                            <button
                                type="button"
                                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600"
                                onClick={() => setShowNewPassword(!showNewPassword)}
                            >
                                {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                            </button>
                        </div>
                    </div>

                    <div className="mb-2 w-full flex flex-col text-xs">
                        <label className="text-xs font-[400] mb-2" htmlFor="confirmPassword">
                            Confirm Password <span className="text-red-500">*</span>
                        </label>
                        <div className="relative">
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="rounded-lg border-[1.5px] border-[#5097A4] w-full pl-3 py-2 h-full focus:border-[#5097A4] outline-none transition duration-300 ease-in-out"
                                placeholder="Confirm new password"
                            />
                            <button
                                type="button"
                                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                                {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                            </button>
                        </div>
                    </div>

                    {/* Submit Button */}
                    <div className="flex justify-center mt-5 sm:mt-2 space-x-4 text-xs w-full">
                        <button
                            type="submit"
                            className="px-3 py-2 text-white bg-[#5097A4] rounded-lg hover:bg-[#47848e] transition duration-300 ease-in-out"
                        >
                            {
                                isLoading ? "Loading.." : "Change Password"
                            }
                        </button>
                    </div>
                </form>
                
            </div>
        </CustomModal>
    );
};

export default ChangePassword;