import Slider from "react-slick";
import "../mobileComponents/silder.css";
import { GoHeart, GoHeartFill, GoShareAndroid } from "react-icons/go";
import { useMemo, useState } from "react";
import { motion } from "framer-motion";
import likeSound from "../mobileComponents/likeSound.mp3";
type Props = {
    productImages: string[];
    isLoading: boolean;
    handleShare: () => void
};

const ProductImageSlider = ({ productImages = [], isLoading, handleShare }: Props) => {
    const [liked, setLiked] = useState(false);
    const [clicked, setClicked] = useState(false);
    const audio = useMemo(() => new Audio(likeSound), [likeSound]);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        arrows: false,
    };

    const start = () => {
        audio.play()
    }

    const handleHeartClick = () => {
        start()
        setLiked(!liked);
        setClicked(true);
        setTimeout(() => {
            setClicked(false);
        }, 500);
    };

    return (
        <div className="w-full h-[430px] overflow-hidden relative">
            {isLoading ? (
                // Skeleton Loader
                <div className="w-full h-[430px] flex flex-col gap-4 items-center justify-center animate-pulse">
                    <div className="w-full h-[410px] bg-gray-300 rounded-lg"></div>
                    <div className="w-[42px] h-[41px] bg-gray-600 rounded-full z-20 absolute top-4 right-3"></div>
                    <div className="w-[42px] h-[41px] bg-gray-600 z-20 rounded-full absolute top-16 right-3"></div>
                </div>
            ) : (
                <>
                    {productImages.length > 1 ? (
                        <Slider {...settings} className="custom-slider">
                            {productImages.map((image: string, index: number) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`Product Image ${index + 1}`}
                                    className="w-full h-[410px] object-cover object-center "
                                />
                            ))}
                        </Slider>
                    ) : productImages.length === 1 ? (
                        <img
                            src={productImages[0]}
                            alt="Product Image"
                            className="w-full h-[410px] object-cover object-center"
                        />
                    ) : (
                        <div className="flex items-center justify-center w-full h-full text-gray-500">
                            No images available
                        </div>
                    )}

                    {/* Heart Button */}
                    <motion.span
                        className={`w-[42px] h-[41px] rounded-full absolute top-3 right-3 flex items-center justify-center transition-all duration-100 ease-in-out ${liked ? 'bg-white' : 'bg-[#C2C2C2]'}`}
                        onClick={handleHeartClick}
                        whileHover={{ scale: 1.2 }}
                        whileTap={{
                            scale: 0.9,
                            rotate: 5,
                            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                        }}
                        animate={{
                            scale: clicked ? 1.3 : 1,
                            opacity: clicked ? 0.8 : 1,
                        }}
                        transition={{
                            scale: { type: "spring", stiffness: 400, damping: 17 },
                            rotate: { type: "spring", stiffness: 300, damping: 20 },
                            opacity: { duration: 0.2 },
                        }}
                    >
                        {liked ? (
                            <GoHeartFill className="text-[23px] text-red-500" />
                        ) : (
                            <GoHeart className="text-[23px] text-black" />
                        )}
                    </motion.span>

                    {/* Share button */}
                    <span onClick={handleShare} className="w-[42px] h-[41px] bg-[#C2C2C2] rounded-full absolute top-16 right-3 flex items-center justify-center">
                        <GoShareAndroid className="text-[23px]" />
                    </span>
                </>
            )}
        </div>
    );
};

export default ProductImageSlider;
