import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';

const DeletedCheck: React.FC = () => {
  const userDetails = useSelector((state: RootState) => state.Login.ContractorData);

  if (!userDetails?.isDeleted && userDetails) {
    return <Outlet />;
  }

  return <Navigate to="/" replace />;
};

export default DeletedCheck;
