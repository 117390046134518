import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ItemStatus from "./InventoryStatus2";
import defaultImage from "../../../assets/item.png";
import { useChangeProductStatusMutation } from "../../../Redux/Api/Admin/Inventory";
import { useGetCompanySearchesQuery } from "../../../Redux/Api/Admin/Company";
import TableNoData from "../../../common/admin-common/TableNoData";
import TableLoading from "../../../common/admin-common/TableLoading";
import useDebounce from "../../../common/hooks/useDebounce";
import { toast } from "react-toastify";

export const InventoryCompanyProduct = () => {
	const { name } = useParams<{ name: string }>();
	const [search, setSearch] = useState<string>("");
	const [openPopupIndex, setOpenPopupIndex] = useState<number | null>(null);
	const debounceSearch = useDebounce(search, 400);
	const { data: ProductResponse, refetch, isLoading } = useGetCompanySearchesQuery({ company: name, search: debounceSearch });
	const [changeStatusMutation, { isError, isSuccess, error }] = useChangeProductStatusMutation();


	const handleTogglePopup = (index: number) => {
		setOpenPopupIndex((prevIndex) => (prevIndex === index ? null : index));
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value);
	};

	const handleStatusChange = async (status: string, _id: string, index: number) => {
		if (status === "publish") {
			await changeStatusMutation({ _id, status: true });
		}
		if (status === "unPublish") {
			await changeStatusMutation({ _id, status: false });
		}
		handleTogglePopup(index);
	};

	useEffect(() => {
		if (isSuccess) {
			toast.success("Product status changed successfully",)
			refetch();
		}
		else if (isError) {
			toast.error(error.message || "Failed to change product status")
		}

	}, [isError, isSuccess, error])

	const navigate = useNavigate()
	const handleItemName = (id: any) => {
		console.log("id", id);
		if (id) {
			navigate(`/admin/category/item/${id}`, {
				state: { id: id },
			});
		}
	};

	return (

		<div className="w-full h-auto px-3 mt-[13px] py-3 mb-10">
			<div className="w-full  bg-white rounded-t-xl mb-4 pt-2">
				{/* Headers */}
				<div className="flex justify-between py-2 px-4">
					<div className="font-medium">Companies</div>
					<div className="flex gap-2">
						<input
							type="text"
							value={search}
							onChange={handleChange}
							className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-xl focus:ring-blue-500 focus:border-[0.5px] focus:border-blue-500 block w-full py-2 px-3"
							placeholder="Search.."
							style={{
								boxShadow:
									"0px 0px 7.6px 0px #00000040 inset",
							}}
						/>
					</div>
				</div>
				{/* Table */}
				<div className="w-full overflow-x-auto">
					<table className="table-auto min-w-full divide-y divide-gray-200 mt-2 relative">
						<thead className="bg-white divide-y divide-gray-200">
							<tr className="border-t">
								<th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]"></th>
								<th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]">
									Image
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Product
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Price
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Description
								</th>
								<th className="px-6 py-3 text-left text-sm font-inter text-[#1E293B]">
									Status
								</th>
								<th className="px-6 py-3 text-left text-sm text-nowrap font-inter text-[#1E293B]"></th>
							</tr>
						</thead>
						<tbody className="bg-white ">
							{isLoading ? (
								<TableLoading colCount={7} />
							) : ProductResponse?.length > 0 ? (
								ProductResponse.map(
									(i: any, index: number) => (
										<tr key={i?._id}>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]"></td>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
												<img
													onError={(
														e: React.SyntheticEvent<
															HTMLImageElement,
															Event
														>
													) => {
														const target =
															e.target as HTMLImageElement;
														target.onerror =
															null;
														target.src =
															defaultImage;
													}}
													src={
														i?.image ||
														defaultImage
													}
													className="w-[30px] h-[30px] rounded-[12px]"
													alt="Product Image"
												/>
											</td>
											<td className="pl-4 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
												<button onClick={() =>
													handleItemName(i?._id)
												} className="hover:text-blue-500 duration-300">{i?.name}</button>
											</td>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
												{i?.netRate.toFixed()}
											</td>
											<td className="px-6 py-4 whitespace-nowrap font-inter text-sm text-[#6E7079]">
												{i?.description}
											</td>
											<ItemStatus
												key={index}
												i={i}
												index={index}
												handleStatusChange={
													handleStatusChange
												}
												openPopupIndex={
													openPopupIndex
												}
												handleTogglePopup={
													handleTogglePopup
												}
											/>
										</tr>
									)
								)
							) : (
								<TableNoData
									colCount={7}
									text={"No Company Product Avaiable"}
								/>
							)}
						</tbody>
					</table>
				</div>
				{/* Product Counts */}
				<div className="flex flex-row justify-between p-3 m-1 border-t-2 border-[#E1E2E9]">
					<div className="flex flex-row gap-3">
						<p className="font-medium"> {ProductResponse?.length || 0}  Company Products</p>
					</div>
				</div>
			</div>
		</div>

	);
};
