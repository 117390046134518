import { lhwpath } from "../apiRoutes";
import { api } from "./rtkAPI";
import { LoginResponse } from "./types";

export const SignupQuery: any = api.injectEndpoints({
	endpoints: (builder) => ({
		Signup: builder.mutation<any, any>({
			query: (formData) => {
				return {
					url: `${lhwpath}/global/contractor/create`,
					method: "POST",
					body: formData,
				};
			},
		}),

		Login: builder.mutation<LoginResponse, string>({
			query: (loginData) => {
				return {
					url: `${lhwpath}/global/contractor/login`,
					method: "POST",
					body: loginData,
				};
			},
			invalidatesTags: ["NavCount"],
		}),
		OtpSend: builder.mutation<any, any>({
			query: (formData) => {
				return {
					url: `${lhwpath}/global/auth/send-otp`,
					method: "POST",
					body: formData,
				};
			},
		}),
		OtpVerification: builder.mutation<any, { phoneNumber: number, otp: number }>({
			query: ({ phoneNumber, otp }) => {
				return {
					url: `${lhwpath}/global/auth/verify-otp`,
					method: "POST",
					body: { phoneNumber, otp },
				};
			},
		}),

		// forget-Password-apis

		ChangePasswordtOtpSend: builder.mutation<any, { phoneNumber: number }>({
			query: ({ phoneNumber }) => {
				return {
					url: `${lhwpath}/global/auth/user/send-otp`,
					method: "POST",
					body: { phoneNumber },
				};
			},
		}),

		ChangePasswordOtpVerification: builder.mutation<any, { phoneNumber: number, otp: number }>({
			query: ({ phoneNumber, otp }) => {
				return {
					url: `${lhwpath}/global/auth/user/verify-otp`,
					method: "POST",
					body: { phoneNumber, otp },
				};
			},
		}),

		ForgetPassword: builder.mutation<any, { phoneNumber: number, otp: number, password: string }>({
			query: ({ phoneNumber, otp, password }) => {
				return {
					url: `${lhwpath}/global/auth/user/reset-password`,
					method: "POST",
					body: { phoneNumber, otp, password },
				};
			},
		}),


	}),
});

export const {
	useSignupMutation,
	useLoginMutation,
	useOtpSendMutation,
	useOtpVerificationMutation,
	useForgetPasswordMutation,
	useChangePasswordtOtpSendMutation,
	useChangePasswordOtpVerificationMutation,


} = SignupQuery;
