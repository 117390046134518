import { gradientStyle2 } from "./StyleComponent";
import Img1 from "../../../assets/assets/DifferentBranchesImg1.png";
import Img2 from "../../../assets/assets/DifferentBranchesImg2.png";
import Img3 from "../../../assets/assets/DifferentBranchesImg3.png";

const DifferentBranchesSection = () => {
    return (
        <div className="w-full h-auto px-3 py-4 space-y-5" style={gradientStyle2}>
            <div className="font-poppins font-bold leading-[21.78px] text-center">
                Three Different Branches
                <br />
                to Support You
            </div>

            <div className="flex w-full gap-3">
                <img src={Img1} alt="Teenpani" className="w-1/2" loading="lazy" />
                <div className="flex flex-col font-poppins gap-1.5 w-1/2 text-start">
                    <div className="font-bold text-[10px] leading-[12.1px]">Teenpani</div>
                    <div className="font-[400] text-[6px] leading-[9px]">
                        Founded in 2002 as Chawla Enterprises, our journey began with a passion for excellence and a vision to become a trusted name in the steel industry. In 2007, we rebranded as Chawla Ispat, marking a new chapter in our commitment to providing high-quality steel and construction materials to businesses across Rudrapur and beyond.
                    </div>
                </div>
            </div>

            <div className="flex w-full gap-3">
                <div className="flex flex-col font-poppins gap-1.5 w-1/2 text-start">
                    <div className="font-bold text-[10px] leading-[12.1px]">Galla Mandi</div>
                    <div className="font-[400] text-[6px] leading-[9px]">
                        With over two decades of experience, Chawla Ispat has grown into one of the most reliable construction material suppliers, known for delivering exceptional quality and service. Our comprehensive range of products includes everything from steel beams and rods to a variety of construction essentials, catering to the needs of builders, contractors, and industrial projects.
                    </div>
                </div>
                <img src={Img2} alt="Galla Mandi" className="w-1/2" loading="lazy" />
            </div>

            <div className="flex w-full gap-3">
                <img src={Img3} alt="Dineshpur" className="w-1/2" loading="lazy" />
                <div className="flex flex-col font-poppins gap-1.5 w-1/2 text-start">
                    <div className="font-bold text-[10px] leading-[12.1px]">Dineshpur</div>
                    <div className="font-[400] text-[6px] leading-[9px]">
                        At Chawla Ispat, we take pride in building long-lasting relationships with our clients by offering personalized solutions, timely deliveries, and competitive pricing. Our team is dedicated to ensuring that each customer receives the best materials for their projects, no matter the size or scope.
                        <br /> <br />
                        Driven by integrity and a commitment to innovation, we continue to be a leading building material supplier in the region, helping build the future, one project at a time.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DifferentBranchesSection;
