import React, { useState, useEffect } from 'react';

interface ProductDescriptionProps {
    singleProduct: any;
}

const ProductDescription: React.FC<ProductDescriptionProps> = ({ singleProduct }) => {
    const [openSections, setOpenSections] = useState<Record<number, boolean>>({});

    useEffect(() => {
        const sectionsWithContent = {
            1: !!singleProduct?.companyProductDescription,
            2: singleProduct?.companyDescriptionLabels?.length > 0,
            3: singleProduct?.categoryDescriptionLabels?.length > 0,
            4: singleProduct?.categoryProductDescriptionLabels?.length > 0,
            5: singleProduct?.companyProductDescriptionLabels?.length > 0,
        };
        setOpenSections(sectionsWithContent);
    }, [singleProduct]);

    const handleToggle = (sectionIndex: number) => {
        setOpenSections((prev) => ({
            ...prev,
            [sectionIndex]: !prev[sectionIndex],
        }));
    };

    const Section = ({
        title,
        index,
        content,
    }: {
        title: string;
        index: number;
        content: React.ReactNode;
    }) => (
        <div className="px-2 sm:px-5">
            {/* Header */}
            <div
                className="flex border-b-[2px] border-[#888888] items-center justify-between gap-2 cursor-pointer"
                onClick={() => handleToggle(index)}
            >
                <div className="flex items-center gap-2 p-4">
                    <svg
                        className={`w-4 h-4 transition-transform ease-in-out transform ${openSections[index] ? 'rotate-180' : 'rotate-0'
                            }`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                    </svg>
                    <div className="font-medium leading-[21px]">{title}</div>
                </div>
            </div>

            {/* Content */}
            <div
                className={`transition-[max-height,opacity]  duration-500 ease-in-out overflow-hidden ${openSections[index] ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
                    }`}
                style={{ maxHeight: openSections[index] ? '1000px' : '0' }}
            >
                <div className="p-4">{content}</div>
            </div>
        </div>
    );

    return (
        <div
            className="w-full 2xl:container relative h-auto overflow-hidden mt-5 mx-auto pb-6 lg:pb-0"
            style={{ background: 'linear-gradient(0deg, #5097A4 0%, #F9F9F9 92.33%, #FFFFFF 97.24%)' }}
        >
            <div className="w-[90%] min-h-[200px] mx-auto bg-white mt-10 border rounded-3xl py-3 pb-5">
                {/* Accordion Header */}
                <div className="w-full py-4 px-5 text-[#d88200] font-semibold cursor-pointer text-lg">
                    Description
                </div>

                {/* Sections */}
                <Section
                    title="Product Description"
                    index={1}
                    content={singleProduct?.companyProductDescription || 'No description available.'}
                />
                <Section
                    title="Company Description"
                    index={2}
                    content={
                        singleProduct?.companyDescriptionLabels?.length > 0 ? (
                            <ul>
                                {singleProduct.companyDescriptionLabels.map((el: any, idx: number) => (
                                    <li key={idx} className="list-disc">
                                        <strong>{el?.key}:</strong> {el?.value}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            'No description available.'
                        )
                    }
                />
                <Section
                    title="Category Description"
                    index={3}
                    content={
                        singleProduct?.categoryDescriptionLabels?.length > 0 ? (
                            <ul>
                                {singleProduct.categoryDescriptionLabels.map((el: any, idx: number) => (
                                    <li key={idx} className="list-disc">
                                        <strong className="mr-1">{el?.key}:</strong> {el?.value}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            'No description available.'
                        )
                    }
                />
                <Section
                    title="Category Product Description"
                    index={4}
                    content={
                        singleProduct?.categoryProductDescriptionLabels?.length > 0 ? (
                            <ul>
                                {singleProduct.categoryProductDescriptionLabels.map((el: any, idx: number) => (
                                    <li key={idx} className="list-disc">
                                        <strong className='mr-1'>:</strong> {el?.value}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            'No description available.'
                        )
                    }
                />
                <Section
                    title="Company Product Description"
                    index={5}
                    content={
                        singleProduct?.companyProductDescriptionLabels?.length > 0 ? (
                            <ul>
                                {singleProduct.companyProductDescriptionLabels.map((el: any, idx: number) => (
                                    <li key={idx} className="list-disc">
                                        <strong className='mr-1'>{el?.key}:</strong> {el?.value}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            'No description available.'
                        )
                    }
                />
            </div>
        </div>
    );
};

export default ProductDescription;

// import React from 'react';


// interface ProductDescriptionProps {
//     singleProduct: any;
//     setIndex: (index: number) => void;
//     index: number;
// }

// const ProductDescription: React.FC<ProductDescriptionProps> = ({ singleProduct, setIndex, index }) => {

//     // Toggle accordion section: allow only one open at a time
//     const handleToggle = (currentIndex: number) => {
//         setIndex(index === currentIndex ? 0 : currentIndex); // Close if already opened
//     };


//     return (
//         <div
//             className="w-full 2xl:container  relative h-auto overflow-hidden mt-5 mx-auto pb-6 lg:pb-0"
//             style={{ background: 'linear-gradient(0deg, #5097A4 0%, #F9F9F9 92.33%, #FFFFFF 97.24%)' }}
//         >
//             <div className="w-[90%] min-h-[200px] mx-auto bg-white mt-10 border rounded-3xl py-3 pb-5">

//                 {/* Accordion Header */}
//                 <div className="w-full py-4 px-5 text-[#d88200] font-semibold cursor-pointer text-lg">
//                     Description
//                 </div>

//                 {/* Product Description */}
//                 <div className=" px-2 sm:px-5">
//                     <div className="flex border-b-[2px] border-[#888888] items-center justify-between gap-2 cursor-pointer" onClick={() => handleToggle(1)}>
//                         <div className="flex items-center gap-2 p-4">
//                             <svg
//                                 className={`w-4 h-4 transition-transform ease-in-out transform ${index === 1 ? 'rotate-180' : 'rotate-0'}`}
//                                 fill="none"
//                                 stroke="currentColor"
//                                 viewBox="0 0 24 24"
//                                 xmlns="http://www.w3.org/2000/svg"
//                             >
//                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
//                             </svg>
//                             <div className="font-medium leading-[21px]">
//                                 Product Description
//                             </div>
//                         </div>
//                     </div>

//                     <div className={`transition-all duration-500 ease-in-out overflow-hidden flex items-center ${index === 1 ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'}`}>
//                         <div className="items-start justify-start p-4 text-[0.875rem]">
//                             {singleProduct?.companyProductDescription || 'No description available.'}
//                         </div>
//                     </div>
//                 </div>

//                 {/* Company Description */}
//                 <div className=" px-2 sm:px-5">
//                     <div className="flex border-b-[2px] border-[#888888] items-center justify-between gap-2 cursor-pointer" onClick={() => handleToggle(2)}>
//                         <div className="flex items-center gap-2 p-4">
//                             <svg
//                                 className={`w-4 h-4 transition-transform ease-in-out transform ${index === 2 ? 'rotate-180' : 'rotate-0'}`}
//                                 fill="none"
//                                 stroke="currentColor"
//                                 viewBox="0 0 24 24"
//                                 xmlns="http://www.w3.org/2000/svg"
//                             >
//                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
//                             </svg>
//                             <div className="font-medium leading-[21px]">
//                                 Company Description
//                             </div>
//                         </div>
//                     </div>

//                     <div className={`transition-all duration-500 ease-in-out overflow-hidden flex items-center ${index === 2 ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'}`}>
//                         <div className="items-start justify-start p-4 w-full">
//                             <ul className="w-[95%] mx-auto">
//                                 {
//                                     singleProduct?.companyDescriptionLabels.length > 0 ? singleProduct?.companyDescriptionLabels?.map((el: any, index: number) => (
//                                         <li className="h-full list-disc w-full" key={index}>
//                                             <div className="w-full flex h-full">
//                                                 <div className="min-w-[25%] h-full font-semibold text-[0.875rem] leading-[1.25rem] pb-2 pt-[0.375rem] px-[0.105rem]">
//                                                     {el?.key?.charAt(0).toUpperCase() + el?.key?.slice(1)}
//                                                 </div>
//                                                 <div className="w-full text-[0.875rem] leading-[1.25rem] pb-2 pt-[0.375rem] px-[0.875rem]">
//                                                     {el?.value}
//                                                 </div>
//                                             </div>
//                                         </li>
//                                     )) : <span className='w-full'>No description available. </span>
//                                 }
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//                 {/* Company Product Description */}
//                 <div className=" px-2 sm:px-5">
//                     <div className="flex border-b-[2px] border-[#888888] items-center justify-between gap-2 cursor-pointer" onClick={() => handleToggle(5)}>
//                         <div className="flex items-center gap-2 p-4">
//                             <svg
//                                 className={`w-4 h-4 transition-transform ease-in-out transform ${index === 5 ? 'rotate-180' : 'rotate-0'}`}
//                                 fill="none"
//                                 stroke="currentColor"
//                                 viewBox="0 0 24 24"
//                                 xmlns="http://www.w3.org/2000/svg"
//                             >
//                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
//                             </svg>
//                             <div className="font-medium leading-[21px]">
//                                 Company Product Description
//                             </div>
//                         </div>
//                     </div>

//                     <div className={`transition-all duration-500 ease-in-out overflow-hidden flex items-center ${index === 5 ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'}`}>
//                         <div className="items-start justify-start p-4 w-full">
//                             <ul className="w-[95%] mx-auto">
//                                 {
//                                     singleProduct?.companyProductDescriptionLabels.length > 0 ? singleProduct?.companyProductDescriptionLabels?.map((el: any, index: number) => (
//                                         <li className="h-full list-disc w-full" key={index}>
//                                             <div className="w-full flex h-full">
//                                                 <div className="min-w-[25%] h-full font-semibold text-[0.875rem] leading-[1.25rem] pb-2 pt-[0.375rem] px-[0.105rem]">
//                                                     {el?.key?.charAt(0).toUpperCase() + el?.key?.slice(1)}
//                                                 </div>
//                                                 <div className="w-full text-[0.875rem] leading-[1.25rem] pb-2 pt-[0.375rem] px-[0.875rem]">
//                                                     {el?.value}
//                                                 </div>
//                                             </div>
//                                         </li>
//                                     )) : <span className='w-full'>No description available. </span>
//                                 }
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//                 {/* Category Description */}
//                 <div className=" px-2 sm:px-5">
//                     <div className="flex border-b-[2px] border-[#888888] items-center justify-between gap-2 cursor-pointer" onClick={() => handleToggle(3)}>
//                         <div className="flex items-center gap-2 p-4">
//                             <svg
//                                 className={`w-4 h-4 transition-transform ease-in-out transform ${index === 3 ? 'rotate-180' : 'rotate-0'}`}
//                                 fill="none"
//                                 stroke="currentColor"
//                                 viewBox="0 0 24 24"
//                                 xmlns="http://www.w3.org/2000/svg"
//                             >
//                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
//                             </svg>
//                             <div className="font-medium leading-[21px]">
//                                 Category Description
//                             </div>
//                         </div>
//                     </div>

//                     <div className={`transition-all duration-500 ease-in-out overflow-hidden flex items-center ${index === 3 ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'}`}>
//                         <div className="items-start justify-start p-4 w-full">
//                             <ul className="w-[95%] mx-auto">
//                                 {singleProduct?.categoryDescriptionLabels.length > 0 ? (
//                                     singleProduct?.categoryDescriptionLabels?.map((el: any, index: number) => (
//                                         <li className="h-full list-disc w-full" key={index}>
//                                             <div className="w-full flex h-full items-start sm:items-center">
//                                                 <div className="w-[40%] sm:w-[30%] font-semibold text-[0.875rem] leading-[1.25rem] pb-2 pt-[0.375rem] px-[0.105rem]">
//                                                     {el?.key?.charAt(0).toUpperCase() + el?.key?.slice(1)}
//                                                 </div>
//                                                 <div className="w-[60%] sm:w-[70%] text-end sm:text-start text-[0.875rem] leading-[1.25rem] pb-2 pt-[0.375rem] px-[0.875rem]">
//                                                     {" "}{el?.value}
//                                                 </div>
//                                             </div>
//                                         </li>
//                                     ))
//                                 ) : (
//                                     <span className="w-full">No description available.</span>
//                                 )}
//                             </ul>

//                         </div>
//                     </div>
//                 </div>
//                 {/* Category Item Description */}
//                 <div className=" px-2 sm:px-5">
//                     <div className="flex border-b-[2px] border-[#888888] items-center justify-between gap-2 cursor-pointer" onClick={() => handleToggle(4)}>
//                         <div className="flex items-center gap-2 p-4">
//                             <svg
//                                 className={`w-4 h-4 transition-transform ease-in-out transform ${index === 4 ? 'rotate-180' : 'rotate-0'}`}
//                                 fill="none"
//                                 stroke="currentColor"
//                                 viewBox="0 0 24 24"
//                                 xmlns="http://www.w3.org/2000/svg"
//                             >
//                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
//                             </svg>
//                             <div className="font-medium leading-[21px]">
//                                 Category Item Description
//                             </div>
//                         </div>
//                     </div>

//                     <div className={`transition-all duration-500 ease-in-out overflow-hidden flex items-center ${index === 4 ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'}`}>
//                         <div className="items-start justify-start p-4 w-full">
//                             <ul className="w-[95%] mx-auto">
//                                 {
//                                     singleProduct?.categoryProductDescriptionLabels.length > 0 ?
//                                         singleProduct?.categoryProductDescriptionLabels?.map((el: any, index: number) => (
//                                             <li className="h-full list-disc w-full" key={index}>
//                                                 <div className="w-full flex h-full">
//                                                     <div className="min-w-[25%] h-full font-semibold text-[0.875rem] leading-[1.25rem] pb-2 pt-[0.375rem] px-[0.105rem]">
//                                                         {el?.key?.charAt(0).toUpperCase() + el?.key?.slice(1)}
//                                                     </div>
//                                                     <div className="w-full text-[0.875rem] leading-[1.25rem] pb-2 pt-[0.375rem] px-[0.875rem]">
//                                                         {el?.value}
//                                                     </div>
//                                                 </div>
//                                             </li>
//                                         )) : <span className='w-full'>No description available. </span>}
//                             </ul>
//                         </div>
//                     </div>
//                 </div>

//             </div>
//         </div>
//     );
// }

// export default ProductDescription;
