import { memo } from "react";
import defaultImage from "../../assets/assets/dummyProduct.png";
import { useNavigate } from "react-router-dom";
import { Company } from "../Components/types";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
interface MobileCompanyCardProps {
	company: Company;
	isGrid: boolean;
}

const MobileCompanyCard = memo(
	({ company, isGrid }: MobileCompanyCardProps) => {
		const navigate = useNavigate();

		const handleItemClick = (_id?: string, name?: string | null) => {
			if (_id) {
				navigate(`/items/${_id}/company/${name}`, { state: { company: _id } });
			} else {
				toast.warning("Something went wrong! Please try again.");
			}
		};


		const cardVariants = {
			grid: { scale: 1, opacity: 1, x: 0 },
			list: { scale: 1, opacity: 0.9, x: 0 },
		};

		return (
			<motion.div
				className="w-full font-poppins"
				layout
				initial="hidden"
				animate={isGrid ? "grid" : "list"}
				variants={cardVariants}
				transition={{ duration: 0.5, ease: "easeInOut" }}>
				{isGrid ? (
					<div
						onClick={() =>
							handleItemClick(company?.id, company?.name)
						}
						className="relative flex flex-col p-2 w-full items-center overflow-hidden rounded-md bg-white  shadow hover:shadow-md border border-[#F1F1F1] cursor-pointer">
						<div className="shrink-0 h-24 w-full   bg-white border rounded-md overflow-hidden flex items-center justify-center">
							<img
								src={
									company?.image
										? company?.image
										: defaultImage
								}
								alt=""
								className="object-contain object-center w-full h-full rounded-md "
							/>
						</div>
						<div className=" w-full space-y-2 mt-2 flex justify-between items-center">
							<p className="font-medium text-gray-800 text-xs">
								{company?.name?.length > 20
									? `${company.name
										.substring(0, 20)
										.trim()}...`
									: company?.name}
							</p>

						</div>
						<div className="flex justify-between w-full mt-0.5">

							<p className="rounded-full w-fit bg-black px-1 text-center text-[8px] font-medium text-white">
								Categ: {company?.productCount}
							</p>
							<button
								onClick={() =>
									handleItemClick(company?.id, company?.name)
								}
								className="flex items-center   font-medium  transition-all duration-300 group text-turkishBlue-default   text-[8px] hover:text-gray-500  rounded-md ">
								See more
								<svg
									className="rtl:rotate-180 w-2 h-2 ms-2 transform transition-transform duration-300 animate-arrowLoop group-hover:animate-none"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 14 10">
									<path
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M1 5h12m0 0L9 1m4 4L9 9"
									/>
								</svg>
							</button>
						</div>
					</div>
				) : (
					<div
						onClick={() =>
							handleItemClick(company?.id, company?.name)
						}
						className="relative flex w-full items-center overflow-hidden rounded-md bg-white p-2 shadow hover:shadow-md border border-[#F1F1F1] cursor-pointer">
						<div className="shrink-0 h-23 w-24  bg-white border rounded-md overflow-hidden flex items-center justify-center">
							<img
								src={
									company?.image
										? company?.image
										: defaultImage
								}
								alt=""
								className="object-contain object-center w-full h-full rounded-md "
							/>
						</div>
						<div className="ml-3 w-full space-y-2">
							<p className="font-medium text-gray-800 text-xs">
								{company?.name?.length > 15
									? `${company.name
										.substring(0, 15)
										.trim()}...`
									: company?.name}
							</p>

							<p className="rounded-full w-fit bg-black px-2 text-center text-[10px] font-medium text-white">
								Categ : {company?.productCount}
							</p>
							<button
								onClick={() =>
									handleItemClick(company?.id, company?.name)
								}
								className="flex items-center justify-center px-3 py-2 text-sm font-medium text-cente transition-all duration-300 group text-turkishBlue-default  ml-auto hover:text-gray-500 rounded-md  ">
								See more
								<svg
									className="rtl:rotate-180 w-3.5 h-3.5 ms-2 transform transition-transform duration-300 animate-arrowLoop group-hover:animate-none"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 14 10">
									<path
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M1 5h12m0 0L9 1m4 4L9 9"
									/>
								</svg>
							</button>
						</div>
					</div>
				)}
			</motion.div>
		);
	}
);

export default MobileCompanyCard;
