import img1 from "../../../assets/assets/service1.png"
import img2 from "../../../assets/assets/service2.png"
import img3 from "../../../assets/assets/service3.png"

type Service = {
    imgSrc: string;
    title: string;
    description: string;
};

const ServicesSection = () => {
    const services: Service[] = [
        { imgSrc: img1, title: "NO. 1 Seller", description: "From 25 years in UK." },
        { imgSrc: img2, title: "Guaranteed Lowest Price ", description: "Lowest Price in Market" },
        { imgSrc: img3, title: "Good Quality Shipment", description: "Delivery on time." },
    ];
    return (
        <div className="w-[96%] mx-auto h-[35px] bg-white rounded-[4px] shadow-sm border flex justify-around ">
            {services.map((service, index) => (
                <div key={index} className="flex items-center space-x-1 ">
                    <img src={service.imgSrc} alt={service.title} className="w-[28px] h-[27px]" />
                    <div>
                        <span className="block text-[7px] leading-[8.47px] font-poppins font-medium">{service.title}</span>
                        <span className="block text-[6px] leading-[8.47px] text-[#5B5B5B] font-[400px]">{service.description}</span>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ServicesSection