import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ImgProps } from "./types";
import deafultImage from "../../assets/item.png";

const ImgCard = ({
	data,
	smallScreen,
	largeScreen,
	category,
	id,
	itemName,
}: ImgProps) => {
	const navigate = useNavigate();
	const { image = "", name = "" } = data || {};

	const handleClick = () => {
		const type = "category";
		navigate(`/items/${id}/${type}/${itemName}`, {
			state: { id: id },
		});
	};

	// if (smallScreen) {
	//   return (
	//     <div
	//       className="bg-[#F6FAFB] flex flex-col border-[2px] rounded-md w-[104px] shadow-lg overflow-hidden"
	//       onClick={handleClick}
	//     >
	//       <div className="w-[104px] h-[87px] md:w-[6.5rem] md:h-[5rem] overflow-hidden border-b-1">
	//         <Link to="/items" className="w-full h-full">
	//           <img src={image ? image : deafultImage} className="w-full h-full object-cover" alt={name} />
	//         </Link>
	//       </div>
	//       <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-center mt-2 font-inter text-sm font-semibold">{name}</div>
	//     </div>
	//   );
	// }
	if (smallScreen) {
		return (
			<div
				className="relative flex flex-col bg-white rounded-xl w-[130px] shadow-lg overflow-hidden border-[2px] border-solid border-gray-100 ring-[3px] ring-white"
				onClick={handleClick}>
				<div className="w-full h-[87px]">
					<Link
						to="/items"
						className="w-full h-full bg-white rounded-xl overflow-hidden  ">
						<img
							src={image ? image : deafultImage}
							className="w-full h-full  object-cover p-1 rounded-xl object-center"
							alt={name}
						/>
					</Link>
				</div>
				<div className="w-full text-start mt-2 font-inter text-sm font-semibold overflow-hidden text-ellipsis whitespace-nowrap ml-2">
					{name}
				</div>
				<div className="w-full text-end pr-2 ">
					<button
						onClick={handleClick}
						className="text-[#007185] text-[8px] text-inter font-medium">
						For more
					</button>
				</div>
			</div>
		);
	}

	if (largeScreen) {
		return (
			<div
				className="bg-[#F6FAFB] sm:w-[140px] md:w-[140px] justify-center flex flex-col"
				onClick={handleClick}>
				<div className="w-[6.5rem] h-[5rem] overflow-hidden mx-auto">
					<Link to="/items">
						<img src={image ? image : deafultImage} alt={name} />
					</Link>
				</div>
				<div className="flex text-center mt-2 mx-auto font-inter text-sm font-semibold">
					{name}
				</div>
			</div>
		);
	}

	if (category) {
		return (
			<div
				className="bg-[#F6FAFB] w-[150px] justify-center flex flex-col"
				onClick={handleClick}>
				<div className="w-[6.5rem] h-[5rem] overflow-hidden mx-auto">
					<Link to="/items">
						<img
							src={
								category?.image ? category?.image : deafultImage
							}
							alt={name}
							className="w-full h-full object-cover"
						/>
					</Link>
				</div>
				<div className="flex text-center mt-2 mx-auto font-inter text-sm font-semibold">
					{name}
				</div>
			</div>
		);
	}

	return null;
};

export default ImgCard;
