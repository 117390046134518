import { ChangeEvent, useEffect, useState } from "react";
import useDebounce from "../../common/hooks/useDebounce";
import { useAllCategoriesQuery } from "../../Redux/Api/Category";
import { Category } from "./types";
import { BiSolidCategory } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface Props {
  isTrue: boolean;
  isMenu: boolean;
  setIsTrue: (val: boolean) => void;
  setIsMenu: (val: boolean) => void;
}
const MobileCategoryFilters = ({ isTrue, setIsTrue, setIsMenu, isMenu }: Props) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");

  const debounceCategoriesSearch = useDebounce(search, 400);
  const { data: categoriesData, isFetching: categoriesFetching } =
    useAllCategoriesQuery(
      debounceCategoriesSearch
        ? { search: debounceCategoriesSearch.trim() }
        : undefined,
      {
        refetchOnMountOrArgChange: true,
      }
    );

  const onChnageHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleItemClick = (_id: string | null, name: string) => {
    const type = "category";
    if (_id) {
      navigate(`/items/${_id}/${type}/${encodeURIComponent(name)}`, {
        state: { id: _id },
      });
      setIsMenu(false)
    }
    else toast.warning("Something went wrong! Please try again.");
  };

  useEffect(() => {
    if (isMenu === false) {
      setSearch("")
    }
  }, [isMenu])

  return (
    <li>
      <button
        type="button"
        onClick={() => setIsTrue(!isTrue)}
        className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg">
        <BiSolidCategory className="w-5 h-5 text-gray-900" />
        <span className="flex-1 ms-3 text-left whitespace-nowrap font-medium">
          Category ({categoriesData?.totalCategory || 0})
        </span>
        <svg
          className={`w-3 h-3 transform transition-transform duration-300 ${isTrue ? "rotate-180" : ""
            }`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6">
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>
      <ul
        className={`${isTrue
          ? " max-h-[140px] bg-gray-50 rounded-md border overflow-y-auto"
          : "max-h-0"
          } overflow-hidden transition-all duration-300 ease-in-out`}>
        {isTrue && (
          <li className="px-2 py-2">
            <input
              type="text"
              onChange={onChnageHandler}
              value={search}
              placeholder="Search categories..."
              className="w-full px-3 py-2 text-gray-700 border text-xs border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-gray-200"
            />
          </li>
        )}
        {categoriesFetching ? (
          <li className="flex items-center justify-center py-4">
            <div className="loader border-t-transparent border-gray-400 animate-spin rounded-full w-6 h-6"></div>
            <span className="ml-2 text-xs text-gray-500">
              Loading...
            </span>
          </li>
        ) : categoriesData?.data?.length === 0 ? (
          <li className="flex items-center justify-center py-4 text-gray-500 text-xs">
            No categories found.
          </li>
        ) : (
          categoriesData?.data?.map((category: Category) => (
            <li key={category.id}>
              <button onClick={() => handleItemClick(category?.id, category?.name)} className="flex text-xs items-center w-full p-1.5 text-gray-900 transition duration-75 rounded-lg pl-11 border-t hover:bg-gray-100">
                {category.name}
              </button>
            </li>
          ))
        )}
      </ul>
    </li>
  );
};

export default MobileCategoryFilters;
