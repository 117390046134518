import { LuBuilding2 } from "react-icons/lu";
import { useLocation, useNavigate } from "react-router-dom";
import { TbCategory2 } from "react-icons/tb";
import { CgDatabase } from "react-icons/cg";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { AiOutlineHome } from "react-icons/ai";

export const Navigation = () => {
    const location = useLocation();
    const current = location.pathname;
    const userDetails = useSelector(
        (state: RootState) => state.Login.ContractorData
    );
    const navigate = useNavigate();

    const [isAtBottom, setIsAtBottom] = useState(false);

    const navItems = [
        {
            path: "/",
            label: "Home",
            icon: <AiOutlineHome className="w-[23px] h-[23px]" />,
        },
        {
            path: "/company",
            label: "Company",
            icon: <LuBuilding2 className="w-[23px] h-[23px]" />,
        },
        {
            path: "/categories",
            label: "Category",
            icon: <TbCategory2 className="w-[23px] h-[23px]" />,
        },
        {
            path: "/profile/quotation-tracker",
            label: "Query Tracker",
            icon: <CgDatabase className="w-[23px] h-[23px]" />,
            condition: userDetails,
        },
    ];

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollPosition = window.scrollY;
            setIsAtBottom(documentHeight - (scrollPosition + windowHeight) < 10);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div
            className={`sticky left-0 right-0 bg-white flex sm:hidden justify-around items-center h-[60px] border-t-[2px] z-30 overflow-hidden transition-all duration-300 ease-in-out ${
                isAtBottom ? "bottom-0 w-full " : "bottom-0  "
            }`}
        >
            {navItems.map(
                (item, i) =>
                    (item.condition === undefined || item.condition) && (
                        <motion.div
                            onClick={() => navigate(item.path)}
                            className={`relative flex flex-col items-center justify-center gap-1 cursor-pointer w-[60px] h-full ${
                                current === item.path
                                    ? "text-turkishBlue-default"
                                    : "text-gray-500"
                            } hover:text-turkishBlue-default transition-all duration-300`}
                            key={i}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <AnimatePresence>
                                {current === item.path && (
                                    <motion.div
                                        layoutId="highlight"
                                        className="h-1 rounded-b-md w-[60px] absolute top-0 bg-turkishBlue-default"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{
                                            duration: 0.3,
                                        }}
                                    />
                                )}
                            </AnimatePresence>
                            <span className="w-[23px] h-[23px]">{item.icon}</span>
                            <span className="text-[10px] font-medium text-nowrap">{item.label}</span>
                        </motion.div>
                    )
            )}
        </div>
    );
};
// import { LuBuilding2 } from "react-icons/lu";
// import { useLocation, useNavigate } from "react-router-dom";
// import { TbCategory2 } from "react-icons/tb";
// import { CgDatabase } from "react-icons/cg";
// import { useSelector } from "react-redux";
// import { RootState } from "../../Redux/store";
// import { useEffect, useState } from "react";
// import { motion, AnimatePresence } from "framer-motion";
// import { AiOutlineHome } from "react-icons/ai";

// export const Navigation = () => {
//     const location = useLocation();
//     const current = location.pathname;
//     const userDetails = useSelector(
//         (state: RootState) => state.Login.ContractorData
//     );
//     const navigate = useNavigate();

//     const [isAtBottom, setIsAtBottom] = useState(false);


//     const navItems = [
//         {
//             path: "/",
//             label: "Home",
//             icon: <AiOutlineHome className="w-[23px] h-[23px]" />,
//         },
//         {
//             path: "/company",
//             label: "Company",
//             icon: <LuBuilding2 className="w-[23px] h-[23px]" />,
//         },
//         {
//             path: "/categories",
//             label: "Category",
//             icon: <TbCategory2 className="w-[23px] h-[23px]" />,
//         },
//         {
//             path: "/profile/quotation-tracker",
//             label: "Query Tracker",
//             icon: <CgDatabase className="w-[23px] h-[23px]" />,
//             condition: userDetails,
//         },
//     ];

//     useEffect(() => {
//         const handleScroll = () => {
//             const windowHeight = window.innerHeight;
//             const documentHeight = document.documentElement.scrollHeight;
//             const scrollPosition = window.scrollY;
//             setIsAtBottom(documentHeight - (scrollPosition + windowHeight) < 10);
//         };

//         window.addEventListener("scroll", handleScroll);
//         return () => window.removeEventListener("scroll", handleScroll);
//     }, []);

//     return (
//         <div
//             className={`sticky left-0 right-0 bg-white flex sm:hidden justify-around items-center h-[60px] border-t-[2px] z-30 overflow-hidden transition-all duration-300 ease-in-out ${
//                 isAtBottom
//                     ? "bottom-0 w-full "
//                     : "bottom-0  "
//             }`}
//         >
//             {navItems.map(
//                 (item, i) =>
//                     (item.condition === undefined || item.condition) && (
//                         <motion.div
//                             onClick={() => navigate(item.path)}
//                             className={`relative flex flex-col items-center justify-center gap-1 cursor-pointer w-[60px] h-full ${
//                                 current === item.path
//                                     ? "text-turkishBlue-default"
//                                     : "text-gray-500"
//                             } hover:text-turkishBlue-default transition-all duration-300`}
//                             key={i}
//                         >
//                             <AnimatePresence>
//                                 {current === item.path && (
//                                     <motion.div
//                                         layoutId="highlight"
//                                         className="h-1 rounded-b-md w-[60px] absolute top-0 bg-turkishBlue-default"
//                                         initial={{ opacity: 0 }}
//                                         animate={{ opacity: 1 }}
//                                         exit={{ opacity: 0 }}
//                                         transition={{
//                                             duration: 0.3,
//                                         }}
//                                     />
//                                 )}
//                             </AnimatePresence>
//                             <span className="w-[23px] h-[23px]">{item.icon}</span>
//                             <span className="text-[10px] font-medium text-nowrap">{item.label}</span>
//                         </motion.div>
//                     )
//             )}
//         </div>
//     );
// };
