import { useNavigate } from "react-router-dom";
import defaultImage from "../../../assets/defaultImage.svg";

const MobileTopSellingCard = ({ data, priceData }: any) => {
    const navigate = useNavigate();
    const unit = priceData.unit;
    const { lowFinalPrice, highFinalPrice } = priceData?.highLowPrice || {};
    const priceRange =
        lowFinalPrice !== undefined && highFinalPrice !== undefined
            ? `₹${Number(lowFinalPrice).toFixed(2)} - ₹${Number(
                highFinalPrice
            ).toFixed(2)}/${unit}`
            : null;
    return (
        <div
            onClick={() =>
                navigate(`/product/${data?.id}/${data?.productName}`, {
                    state: { id: data?.id },
                })
            }
            className="col-span-1 shrink-0 bg-white shadow-md rounded-lg overflow-hidden transform transition-transform hover:scale-105"
        >
            <div className="w-full bg-gray-200 h-36">
                <img
                    src={data?.image || defaultImage}
                    alt="Product"
                    loading="lazy"
                    className="w-full h-full object-cover rounded-t-lg"
                    onError={(e) => {
                        e.currentTarget.onerror = null;
                        e.currentTarget.src = defaultImage;
                    }}
                />
            </div>
            <div className="p-3 text-center">
                <span className="font-medium text-xs tracking-[0.1px] text-slate-800 truncate block">
                    {data?.productName}
                </span>
                <span className="text-[10px] text-green-500 font-medium">{priceRange}</span>
                <button
                    className="mt-2 w-full text-white text-[10px] font-semibold bg-[#0F172B] rounded-md py-1 transition-all duration-300 hover:bg-[#1E293B]"
                    onClick={() =>
                        navigate(`/product/${data?.id}/${data?.productName}`, {
                            state: { id: data?.id },
                        })
                    }
                >
                    Purchase Now
                </button>
            </div>
        </div>
    );
};

export default MobileTopSellingCard;
