import React from "react";
import { FaHandshake } from "react-icons/fa";
import img1 from "../../../assets/homeImageSection1.jpeg";
import img2 from "../../../assets/homeImageSection2.jpeg";
import { useNavigate } from "react-router-dom";
const AboutSection = () => {
	const navigate = useNavigate();
	return (
		<div className="w-full bg-[#5097A4] rounded-[14px] lg:rounded-[70px] px-3 py-3 sm:p-[30px] flex flex-col justify-center gap-[12px]  lg:my-6">
			<div className="w-full h-full rounded-[14px] flex flex-col justify-center lg:flex-row gap-[20px] lg:my-4 text-justify">
				{/* Image Section */}
				<div className="hidden lg:flex rounded-[14px] lg:w-[300px] h-[300px] 2xl:h-[250px] overflow-hidden">
					<img
						src={img2}
						className="w-full h-full object-cover rounded-[14px] mt-2"
						alt="chaw"
					/>
				</div>

				{/* Text Section */}
				<p className="font-normal leading-normal   w-full flex flex-wrap text-sm md:text-[17px]  text-white">
					Founded in 2002 as Chawla Enterprises, our journey began
					with a passion for excellence and a vision to become a
					trusted name in the steel industry. In 2007, we rebranded as
					Chawla Ispat, marking a new chapter in our commitment to
					providing high-quality steel and construction materials to
					businesses across Rudrapur and beyond. With over two decades
					of experience, Chawla Ispat has grown into the most reliable
					construction material supplier in Rudrapur, known for
					delivering exceptional quality and service. Our
					comprehensive range of products includes everything from
					steel beams and rods to various construction essentials,
					catering to the needs of builders, contractors, and
					industrial projects. As one of the leading steel suppliers
					in Rudrapur, we are proud to offer top-grade steel products
					designed to meet the highest durability and performance
					standards. At Chawla Ispat, we build long-lasting
					relationships with our clients by offering personalized
					solutions, timely deliveries, and competitive pricing. Our
					team is dedicated to ensuring that each customer receives
					the best project materials, regardless of size or scope.
					Driven by integrity and a commitment to innovation, we
					continue to be a leading building material supplier in
					Rudrapur, helping build the future, one project at a time.
				</p>
			</div>

			{/* Call-to-Action Button */}
			<div className="flex justify-center w-full">
				<div
					className="group relative w-[151px] h-[48px] cursor-pointer"
					onClick={() => navigate("/contactus")}>
					{/* Default State */}
					<div className="bg-[#EAEDED] flex h-[48px] w-[151px] justify-center items-center border rounded-tr-[50px] rounded-bl-[50px] gap-[4px] shadow-lg shadow-[#475569] transition-opacity duration-300 absolute inset-0 group-hover:opacity-0">
						<FaHandshake className="text-[#FC5220] w-[19px] h-[18px]" />
						<div className="text-slate-500 text-sm font-semibold font-['Inter'] leading-tight">
							Get in touch
						</div>
					</div>
					{/* Hover State */}
					<div className="bg-[#FC5220] flex h-[48px] w-[151px] justify-center items-center border rounded-tr-[50px] rounded-bl-[50px] gap-[4px] shadow-lg transition-all duration-300 opacity-0 group-hover:opacity-100 group-hover:shadow-2xl absolute inset-0">
						<FaHandshake className="text-white w-[19px] h-[18px] opacity-0 transition-opacity duration-100 delay-300 group-hover:opacity-100" />
						<div className="text-white text-sm font-semibold font-['Inter'] leading-tight transition-transform duration-300 transform translate-x-full group-hover:translate-x-0">
							Get in touch
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutSection;
